import React, {FC} from "react";
import {Customer, Sla, SlaRuleType, TicketPriority} from "../../../api/dto";
import {useApiCall} from "../../../api/api";
import {useFetchedResource} from "../../../api/APIContext";
import {useRefreshEffect} from "../../RefreshController";
import {useModal} from "../../layout/ModalProvider";
import {AddCustomerSLAModal} from "../../../modals/AddCustomerSLAModal";
import {DeleteCustomerSLAModal} from "../../../modals/DeleteCustomerSLAModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileContract, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, IconButton} from "../../form/Button";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Accordion} from "../../data/Accordion";

export const CustomerSlaTab: FC<{priorities?: TicketPriority[], customer?: Customer }> = (props) => {
  const {getSlaForCustomer, getAllSlas} = useApiCall()
  const {resource: SlaItems, reload: reloadSla, loading: loadSla} = useFetchedResource(() => getAllSlas())
  const {resource: customerSla, loading: loadCustomerSla, reload: reloadCustomerSla} = useFetchedResource((customer: string) => getSlaForCustomer(customer))
  useRefreshEffect(() => {
    if (props.customer === undefined) {
      console.warn('customer uuid is undefined')
      return
    }
    reloadSla(undefined)
    reloadCustomerSla(props.customer.id)
  })

  return <>
    {customerSla &&
      <RenderSla sla={customerSla[0]} slaItems={SlaItems ?? []} customer={props.customer} priorities={props.priorities} />
    }
  </>
}

export const RenderSla: FC<{ sla?: Sla, slaItems?: Sla[], priorities?: TicketPriority[], customer?: Customer}> = (props) => {
  const {open: openAddSLAModal} = useModal({title: 'SLA toevoegen', body: <AddCustomerSLAModal customer={props.customer!} slas={props.slaItems!}/>, size: "md"})
  const {open: openDeleteSLAModal} = useModal({title: 'SLA verwijderen', body: <DeleteCustomerSLAModal customer={props.customer!} slas={props.slaItems!}/>, size: "md"})
  const rulesMap: { [ruleType: string]: { [priorityId: string]: { [timeWindow: string]: string } } } = {}
  const headerStyle = 'font-medium text-sm text-left pr-8 py-2 whitespace-nowrap'
  const dataStyle = 'text-sm text-left pr-8 py-2 whitespace-nowrap'

  {props.sla && props.priorities &&
  props.sla.rules.forEach((rule) => {
    if (!rulesMap[rule.rule_type]) {
      rulesMap[rule.rule_type] = {};
    }
    if (!rulesMap[rule.rule_type][rule.priority_id]) {
      rulesMap[rule.rule_type][rule.priority_id] = {};
    }
    const hoursKey = rule.during_office_hours ? 'inside_office_hours' : 'outside_office_hours';
    rulesMap[rule.rule_type][rule.priority_id][hoursKey] = rule.value;
  });
  }

  function slaNameRefactor(header: string): string {
    switch (header) {
      case SlaRuleType.maxResponse:
        return 'Maximale reactie tijd (in minuten)';
      case SlaRuleType.maxSolve:
        return 'Maximale oplos tijd (in minuten)';
      case SlaRuleType.uptime:
        return 'Uptime (in %)';
      case SlaRuleType.supportHours:
        return 'Aantal support uren';
      default:
        return 'Unknown SLA Rule';
    }
  }

  return <div className={"flex flex-col flex-1 border border-slate-200 dark:border-zinc-600 dark:bg-zinc-700 shadow rounded px-4 py-2 space-y-4"}>
    <div className={"flex flex-row justify-between"}>
      <div className={"flex space-x-2 items-center"}>
        <div className={"rounded-full text-brand-700 dark:text-brand-200 bg-brand-200 dark:bg-brand-700 flex items-center justify-center h-10 w-10"}>
          <FontAwesomeIcon className={""} icon={faFileContract}/>
        </div>
        <p>SLA</p>
      </div>

      {props.sla &&
        <div className={"flex space-x-2 items-center"}>
          <div className={"flex items-center justify-center h-10 w-10"}>
            <IconButton type={"secondary"} size={"sm"} icon={faPlus} onClick={openAddSLAModal}/>
          </div>

          <div className={"flex items-center justify-center h-10 w-10"}>
            <IconButton type={"danger"} size={"sm"} icon={faTrash} onClick={openDeleteSLAModal}/>
          </div>
        </div>
      }
    </div>


    {props.sla && props.priorities ?
      <div>
        <Accordion header={props.sla.sla_name}
                   data={Object.entries(rulesMap).map(([ruleType, priorityMap]) => {
                     return {
                       itemHeader: slaNameRefactor(ruleType),
                       children: <table>
                         <thead>
                         <tr>
                           <th className={headerStyle}>Prioriteit</th>
                           <th className={headerStyle}>Tijdens kantooruren</th>
                           <th className={headerStyle}>Buiten kantooruren</th>
                         </tr>
                         </thead>
                         <tbody>
                         {Object.entries(priorityMap).map(([priorityId, {
                           inside_office_hours,
                           outside_office_hours
                         }]) => {
                           return <tr key={priorityId} className={""}>
                             <th
                               // @ts-ignore
                               className={headerStyle}>{props.priorities.find(priority => priority.id === priorityId)?.priority}</th>
                             <td className={dataStyle}>{inside_office_hours ? inside_office_hours : 'Nog niet ingesteld'}</td>
                             <td className={dataStyle}>{outside_office_hours ? outside_office_hours : 'Nog niet ingesteld'}</td>
                           </tr>
                         })}
                         </tbody>
                       </table>
                     }
                   })}
        />
      </div>
      :
      <div className={"flex flex-col flex-1 justify-center items-center"}>
        <h1>Deze klant heeft nog geen SLA</h1>
        <Button icon={faPlus} type={"secondary"} size={"sm"} text={"SLA toevoegen"} onClick={openAddSLAModal}/>
      </div>
    }
  </div>
}