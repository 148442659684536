import React, {FC, useEffect} from "react";
import {useApi, useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {Ticket, TicketAttachment, TicketNote, TicketReply} from "../api/dto";
import {useTenant} from "../tenant/TenantContext";

interface PermissionContextType {
  // Ticket details
  canEditTicketDescription(ticket: Ticket): boolean,
  canAccessTicketTasks(): boolean,
  canAccessTicketTimes(): boolean,
  canEditTicketDescription(ticket: Ticket): boolean,
  canAddTicket(): boolean,

  // Ticket notes
  canCreateTicketNote(): boolean,
  canEditTicketNote(ticketNote: TicketNote): boolean,

  // Ticket Replies
  canCreateTicketReply(): boolean,
  canEditTicketReply(ticketReply: TicketReply): boolean,

  // Ticket Attachments
  canDeleteAttachment(ticketAttachment: TicketAttachment): boolean,

  // Customer
  canAddCustomers(): boolean,
  canEditCustomers(): boolean,
  canDeleteCustomers(): boolean,

  // Team
  canAccessTeam(): boolean,

  // SLA
  canReadSLA(): boolean,

  // User
  canAddOrRemoveAccess(): boolean,
}

export const PermissionContext = React.createContext<PermissionContextType>({} as PermissionContextType)

export const PermissionContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {currentTenant} = useApi()

  const context: PermissionContextType = {
    // Ticket details
    canEditTicketDescription: (ticket) => ticket.user_id === currentTenant?.userId,
    canAccessTicketTasks: () => currentTenant?.permissions.some(p => p.startsWith('ticket_task:browse')) ?? false,
    canAccessTicketTimes: () => currentTenant?.permissions.some(p => p.startsWith('ticket_time:browse')) ?? false,
    canAddTicket:() => currentTenant?.permissions.some(p => p.startsWith('ticket:add')) ?? false,

    // Ticket notes
    canEditTicketNote: (ticketNote) => ticketNote.userId === currentTenant?.userId,
    canCreateTicketNote: () => currentTenant?.permissions.some(p => p.startsWith('ticket_note:add')) ?? false,

    canEditTicketReply: (ticketReply) => ticketReply.userId === currentTenant?.userId,
    canCreateTicketReply: () => true,

    canDeleteAttachment: (ticketAttachment)=> ticketAttachment.userId === currentTenant?.userId,

    // Customer
    canAddCustomers: () => currentTenant?.permissions.some(p => p.startsWith('customer:add')) ?? false,
    canEditCustomers: () => currentTenant?.permissions.some(p => p.startsWith('customer:edit')) ?? false,
    canDeleteCustomers: () => currentTenant?.permissions.some(p => p.startsWith('customer:delete')) ?? false,

    // Team
    canAccessTeam: () => currentTenant?.permissions.some(p => p.startsWith('team:read')) ?? false,

    // SLA
    canReadSLA: () => currentTenant?.permissions.some(p => p.startsWith('sla:read')) ?? false,

    // User
    canAddOrRemoveAccess: () => currentTenant?.permissions.some(p => p.startsWith('user:edit')) ?? false,
  }
  return <PermissionContext.Provider value={context}>
    {children}
  </PermissionContext.Provider>
}


export function usePermission() {
  return React.useContext(PermissionContext)
}