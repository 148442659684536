import {Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faBuilding,
  faChartPie,
  faChevronDown, faChevronLeft, faChevronRight,
  faChevronUp,
  faCircleNotch, faDollarSign,
  faPerson,
  faSearch, faTag, faTimesCircle, faUser, faUserGroup, IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import * as fal from "@fortawesome/pro-light-svg-icons"
import {useApiCall} from "../api/api";
import {IconDropdownButton} from "../components/form/IconButtonDropdown";
import {useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {Contract, Customer, Team, Ticket, TicketStatus, TicketTime, TicketTimeCategory, User} from "../api/dto";
import {faDollar, faDollarCircle, faCircle, faPeople} from "@fortawesome/pro-light-svg-icons";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {faFileSignature} from "@fortawesome/pro-regular-svg-icons";
import {formatTimeString} from "../util/formatTimeString";
import {Select} from "../components/form/Select";
import {Button} from "../components/form/Button";
import {Toggle} from "../components/form/Toggle";
import {Input, InputControl} from "../components/form/Input";
import {faRefresh} from "@fortawesome/pro-solid-svg-icons";
import {usePersistentState} from "../util/usePersistentState";

const EMPTY = '-'
type ReportGroupingAxis = 'customer' | 'team' | 'contract' | 'category' | 'user' | 'ticket'

export const Reports: FC = () => {
  // Endpoints
  const {getAllTicketsForTenant, getContracts, getCustomers, getTicketTimes, getAllStatuses, getTicketTimeCategories, getTeams, getAllUsersForTenant} = useApiCall()
  // Resources
  const {resource: customers, loading: loadCustomers, reload: reloadCustomer} = useFetchedResource(getCustomers)
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(getAllTicketsForTenant)
  const {resource: statuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(getAllStatuses)
  const {resource: contracts, loading: loadContracts, reload: reloadContracts} = useFetchedResource(getContracts)
  const {resource: categories, loading: loadCategories, reload: reloadCategories} = useFetchedResource(getTicketTimeCategories)
  const {resource: teams, loading: loadTeams, reload: reloadTeams} = useFetchedResource(getTeams)
  const {resource: users, loading: loadUsers, reload: reloadUsers} = useFetchedResource(getAllUsersForTenant)
  // State
  const [from, setFrom] = useState<Moment>(moment().startOf("month"))
  const [to, setTo] = useState<Moment>(moment().endOf("month"))
  const [primaryGrouping, setPrimaryGrouping] = useState<ReportGroupingAxis>('customer')
  const [secondaryGrouping, setSecondaryGrouping] = useState<ReportGroupingAxis | null>(null)
  const [tertiaryGrouping, setTertiaryGrouping] = useState<ReportGroupingAxis | null>(null)
  const [loadTimes, setIsLoading] = useState<boolean>(false)
  const [customerFilter, setCustomerFilter] = useState<string[]>([])
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const [userFilter, setUserFilter] = useState<string[]>([])
  const [teamFilter, setTeamFilter] = useState<string[]>([])
  const [contractFilter, setContractFilter] = useState<string[]>([])
  const [includeContractCategories, setIncludeContractCategories] = useState<boolean>(false)
  const [roundTotalTime, setRoundTotalTime] = usePersistentState<boolean>('roundTimes', false)

  const loading = [
    loadTimes,
    loadCustomers,
    loadTickets,
    loadStatuses,
    loadContracts,
    loadCategories,
    loadTeams,
    loadUsers,
    loadTimes,
  ]

  useRefreshEffect(() => {
    reloadCustomer(undefined)
    reloadTickets(undefined)
    reloadStatuses(undefined)
    reloadContracts(undefined)
    reloadCategories(undefined)
    reloadTeams(undefined)
    reloadUsers(undefined)
  })
  const [ticketTimes, setTicketTimes] = useState<TicketTime[]>([])
  useEffect(() => {
    setIsLoading(true)
    getTicketTimes(from.toDate(), to.toDate()).then((times) => {
      setTicketTimes(times)
      setIsLoading(false)
    })
  }, [from, to]);


  if (loading.some(i => i)) {
    return <div className={'mt-48 flex flex-col items-center space-y-8'}>
      {/*<FontAwesomeIcon icon={fal.faPieChart} className={'text-brand-600 dark:text-brand-500 text-4xl'}/>*/}
      <div
        className={"h-16 w-16 bg-slate-100 dark:bg-brand-700 text-slate-700 dark:text-brand-200 rounded-lg mr-4 flex items-center justify-center"}>
        <FontAwesomeIcon icon={fal.faPieChart} className={"text-3xl"}/>
      </div>
      <h2 className={'font-medium text-lg'}>Data verzamelen...</h2>
      <div className={'w-96 rounded-full mx-auto h-2 bg-slate-300 dark:bg-zinc-500 shadow-lg'}>
        <div className={'h-2 rounded-full bg-brand-600 dark:bg-brand-500 transition-all'}
             style={{width: `${Math.round((loading.filter(x => !x).length / loading.length) * 100)}%`}}></div>
      </div>
    </div>
  }

  return (
    <ContentContainer>
      <PageIconHeader icon={faChartPie}>
        <Breadcrumbs crumbs={[]} currentPage={"Rapportages"}/>
        <PageHeader>Rapportages</PageHeader>
      </PageIconHeader>

      {customers && tickets && contracts && statuses && users && teams && categories &&
        <ReportSearchFilter customer={customers} customerFilter={customerFilter} setCustomerFilter={setCustomerFilter}
                            user={users} userFilter={userFilter} setUserFilter={setUserFilter}
                            status={statuses} statusFilter={statusFilter} setStatusFilter={setStatusFilter}
                            teams={teams} teamsFilter={teamFilter} setTeamsFilter={setTeamFilter}
                            categories={categories} categoriesFilter={categoryFilter} setCategoriesFilter={setCategoryFilter}
                            contract={contracts} contractFilter={contractFilter} setContractFilter={setCategoryFilter}
                            includeContracts={includeContractCategories} setIncludeContracts={(x) => setIncludeContractCategories(x)}
                            from={from} setFrom={setFrom} to={to} setTo={setTo}
        />}
      <div className={'mt-8 rounded border border-slate-100 bg-white dark:bg-zinc-700 dark:border-zinc-600'}>
        <ReportGroup
          primaryGrouping={primaryGrouping}
          setPrimaryGrouping={setPrimaryGrouping}
          secondaryGrouping={secondaryGrouping}
          setSecondaryGrouping={setSecondaryGrouping}
          tertiaryGrouping={tertiaryGrouping}
          setTertiaryGrouping={setTertiaryGrouping}
          roundTotalTime={roundTotalTime}
          setRoundTotalTime={setRoundTotalTime}
        />
        <ReportResults
          times={ticketTimes ?? []}
          customers={customers ?? []}
          statuses={statuses ?? []}
          teams={teams ?? []}
          categories={categories ?? []}
          contracts={contracts ?? []}
          users={users ?? []}
          tickets={tickets ?? []}
          includeContractCategories={includeContractCategories}
          customerFilter={customerFilter}
          statusFilter={statusFilter}
          teamFilter={teamFilter}
          categoryFilter={categoryFilter}
          userFilter={userFilter}
          contractFilter={contractFilter}
          // Grouping
          primaryGrouping={primaryGrouping}
          secondaryGrouping={secondaryGrouping}
          tertiaryGrouping={tertiaryGrouping}
          // Time formatting
          roundTime={roundTotalTime}
        />
      </div>
    </ContentContainer>
  )
}
interface ReportResultsProps {
  // Resources
  times: TicketTime[],
  customers: Customer[],
  statuses: TicketStatus[],
  teams: Team[],
  contracts: Contract[],
  categories: TicketTimeCategory[],
  users: User[],
  tickets: Ticket[],

  // Filters
  // Filter multiple items by ID, or pass null to disable filter
  customerFilter: Customer['id'][]|null,
  statusFilter: TicketStatus['id'][]|null,
  teamFilter: Team['id'][]|null,
  categoryFilter: TicketTimeCategory['id'][]|null,
  includeContractCategories: boolean,
  userFilter: User['id'][]|null,
  contractFilter: Contract['id'][]|null,

  // Groupings
  // Group by 1, 2 or 3 axis, or pass null to disable grouping. It is not allowed to a tertiary grouping without a secondary grouping.
  primaryGrouping: ReportGroupingAxis,
  secondaryGrouping: ReportGroupingAxis | null,
  tertiaryGrouping: ReportGroupingAxis | null,

  // Time formatting
  roundTime: boolean
}
interface ReportItem {
  id: string,
  ticketId?: string
  ticketTitle?: string
  customerId?: string
  customerName?: string
  teamId?: string
  teamName?: string
  userId?: string
  userName?: string
  categoryId?: string
  categoryName?: string
  contractId?: string
  contractName?: string
  statusId?: string
  statusName?: string
  description: string,
  value: number,
  startedAt: Date,
  createdAt: Date,
  updatedAt: Date,
}
interface GroupedReportItems {
  [group: string]: ReportItem[] | GroupedReportItems
}
const ReportResults: FC<ReportResultsProps> = (props) => {
  const items = useMemo(() => {
    const ticketMap = Object.fromEntries(props.tickets.map((ticket) => [ticket.id, ticket]))
    const customerMap = Object.fromEntries(props.customers.map((customer) => [customer.id, customer]))
    const teamMap = Object.fromEntries(props.teams.map((team) => [team.id, team]))
    const categoryMap = Object.fromEntries(props.categories.map((category) => [category.id, category]))
    const contractMap = Object.fromEntries(props.contracts.map((contract) => [contract.id, contract]))
    const statusMap = Object.fromEntries(props.statuses.map((status) => [status.id, status]))
    return props.times.map(time => {
      const ticket = ticketMap[time.ticket_uuid ?? '-'] ?? null
      const category = (props.includeContractCategories && time.contract_id !== null) ? contractMap[time.contract_id]?.timeCategoryId : time.category_id
      return {
        ...time,
        userId: time.user_uuid,
        userName: time.user_name,
        ticketId: time.ticket_uuid,
        ticketTitle: ticket ? `#${ticket.number} - ${ticket.subject?.slice(0,60)}` : null,
        customerId: ticket?.customer_id,
        customerName: `${customerMap[ticket?.customer_id ?? '-']?.name ?? ''} ${customerMap[ticket?.customer_id]?.referenceNumber !== null ? `#${customerMap[ticket?.customer_id]?.referenceNumber}` : ''}`,
        teamId: ticket?.team_id,
        teamName: teamMap[ticket?.team_id ?? '-']?.team_name,
        categoryId: category,
        categoryName: categoryMap[category ?? '-']?.name,
        contractId: time.contract_id,
        contractName: contractMap[time.contract_id ?? '-']?.name,
        statusId: ticket?.status_id,
        statusName: statusMap[ticket?.status_id ?? '-']?.status,
      }
    }) as ReportItem[]

  }, [
    props.tickets,
    props.customers,
    props.teams,
    props.categories,
    props.contracts,
    props.times,
    props.includeContractCategories,
  ])

  // Apply all filters
  const filteredTimes = useMemo(() => {
    return items.filter((item) => {
      if (props.customerFilter !== null && props.customerFilter.length !== 0) {
        if(! props.customerFilter!.includes(item.customerId ?? EMPTY)) {
          return false
        }
      }
      if (props.statusFilter !== null && props.statusFilter.length !== 0) {
        if(! props.statusFilter!.includes(item.statusId ?? EMPTY)) {
          return false
        }
      }
      if (props.teamFilter !== null && props.teamFilter.length !== 0) {
        if(! props.teamFilter!.includes(item.teamId ?? EMPTY)) {
          return false
        }
      }
      if (props.categoryFilter !== null && props.categoryFilter.length !== 0) {
        if(! props.categoryFilter!.includes(item.categoryId ?? EMPTY)) {
          return false
        }
      }
      if (props.userFilter !== null && props.userFilter.length !== 0) {
        if (!props.userFilter!.includes(item.userId ?? EMPTY)) {
          return false
        }
      }
      return true
    })
  }, [items, props.customerFilter, props.statusFilter, props.teamFilter, props.categoryFilter, props.contractFilter, props.userFilter])

  function getItemGroupKey(item: ReportItem, axis: ReportGroupingAxis): string {
    switch(axis) {
      case 'customer':
        return item.customerName ?? EMPTY
      case 'team':
        return item.teamName ?? EMPTY
      case 'contract':
        return item.contractName ?? EMPTY
      case 'category':
        return item.categoryName ?? EMPTY
      case 'user':
        return item.userName ?? EMPTY
      case 'ticket':
        return item.ticketTitle ?? EMPTY
    }
  }
  function groupItems(items: ReportItem[], axis: ReportGroupingAxis): {[key: string]: ReportItem[]} {
    const grouped: {[key: string]: ReportItem[]} = {}
    items.forEach((item) => {
      const key = getItemGroupKey(item, axis)
      if (!grouped[key]) {
        grouped[key] = []
      }
      grouped[key].push(item)
    })
    return grouped
  }

  const groupedItems: GroupedReportItems = useMemo(() => {
    return Object.fromEntries(Object.entries(groupItems(filteredTimes, props.primaryGrouping)).map(([primaryKey, primaryItems]) => {
      return [primaryKey, props.secondaryGrouping === null ? primaryItems : Object.fromEntries(Object.entries(groupItems(primaryItems, props.secondaryGrouping!)).map(([secondaryKey, secondaryItems]) => {
        return [secondaryKey, props.tertiaryGrouping === null ? secondaryItems : Object.fromEntries(Object.entries(groupItems(secondaryItems, props.tertiaryGrouping!)).map(([tertiaryKey, tertiaryItems]) => {
          return [tertiaryKey, tertiaryItems]
        }))]
      }))]
    }))
  }, [filteredTimes, props.primaryGrouping, props.secondaryGrouping, props.tertiaryGrouping])

  const maxDisplayLength = 80

  return <div className={"border-t border-slate-200 dark:border-zinc-600 mt-4"}>
    {groupedItems && Object.entries(groupedItems).map(([primaryKey, primaryItems]) => {
      return <GroupedReportItemRow group={primaryKey} value={primaryItems} key={primaryKey} roundTime={props.roundTime} />
    })}
  </div>
}

function getGroupedReportItems(items: ReportItem[]|GroupedReportItems): ReportItem[] {
  if (Array.isArray(items)) {
    return items
  }
  return Object.values(items).flatMap((subItems) => {
    return getGroupedReportItems(subItems)
  })
}

const GroupedReportItemRow: FC<{group: string, value: ReportItem[]|GroupedReportItems, roundTime: boolean}> = props => {
  const [expanded, setExpanded] = useState(false)
  const allItemsAndSubItems = useMemo(() => getGroupedReportItems(props.value), [props.value])
  const totalValue = allItemsAndSubItems.reduce((acc, item) => {
    const numericValue = item.value || 0;
    return acc + numericValue;
  }, 0);
  return <div>
    <div className={'h-10 flex items-center hover:bg-slate-200 dark:hover:bg-zinc-600 cursor-pointer'} onClick={() => setExpanded(x => !x)}>
      <div className={'h-10 w-10 flex items-center justify-center'}>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </div>
      <div className={'mx-4 font-bold flex-1'}>{props.group}</div>
      <div className={'pr-4'}>{expanded ? '' : formatTimeString(totalValue, props.roundTime, 15)}</div>
    </div>
    {expanded && <div className={'pl-10 pb-2'}>
      {Array.isArray(props.value) ? props.value.map((item, i) => {
        return <div className={'h-10 flex items-center hover:bg-slate-200 dark:hover:bg-zinc-600'} key={i}>
          <div className={'mx-4 font-medium text-slate-700 dark:text-zinc-400 flex-1'}>{item.description.length > 80 ? item.description.substring(0,80) + '...' : item.description}</div>
          <div className={'pr-4'}>{formatTimeString(item.value, props.roundTime, 15)}</div>
        </div>
      }) : Object.entries(props.value).map(([key, value]) => {
        return <GroupedReportItemRow group={key} value={value} key={key} roundTime={props.roundTime}/>
      })}
    </div>}
  </div>
}

const ReportGroup: FC<{
  primaryGrouping: ReportGroupingAxis,
  setPrimaryGrouping: (axis: ReportGroupingAxis) => void,
  secondaryGrouping: ReportGroupingAxis | null,
  setSecondaryGrouping: (axis: ReportGroupingAxis | null) => void,
  tertiaryGrouping: ReportGroupingAxis | null,
  setTertiaryGrouping: (axis: ReportGroupingAxis | null) => void,
  roundTotalTime: boolean,
  setRoundTotalTime: Dispatch<SetStateAction<boolean>>,
}> = (props) => {
  useEffect(() => {
    if (props.tertiaryGrouping && !props.secondaryGrouping) {
      props.setTertiaryGrouping(null)
    }
    if (props.tertiaryGrouping && (props.tertiaryGrouping === props.secondaryGrouping || props.tertiaryGrouping === props.primaryGrouping)) {
      props.setTertiaryGrouping(null)
    }
    if (props.secondaryGrouping && props.secondaryGrouping === props.primaryGrouping) {
      props.setSecondaryGrouping(null)
    }
  }, [props.primaryGrouping, props.secondaryGrouping, props.tertiaryGrouping]);
  return <div className={'grid grid-cols-5 gap-4 mx-4 my-3'}>
    <Select label={'Groeperen op...'} options={{
      'customer': 'Klant',
      'team': 'Team',
      'contract': 'Contract',
      'category': 'Tijd Categorie',
      'user': 'Gebruiker',
      'ticket': 'Ticket',
    }} value={props.primaryGrouping} onChange={(e) => props.setPrimaryGrouping(e as ReportGroupingAxis)}/>
    <Select label={'En op...'} options={{
      '-': 'Geen',
      ...(props.primaryGrouping === 'customer' ? {} : {'customer': 'Klant'}),
      ...(props.primaryGrouping === 'team' ? {} : {'team': 'Team'}),
      ...(props.primaryGrouping === 'contract' ? {} : {'contract': 'Contract'}),
      ...(props.primaryGrouping === 'category' ? {} : {'category': 'Tijd Categorie'}),
      ...(props.primaryGrouping === 'user' ? {} : {'user': 'Gebruiker'}),
      ...(props.primaryGrouping === 'ticket' ? {} : {'ticket': 'Ticket'}),
    }} value={props.secondaryGrouping === null ? '-' : props.secondaryGrouping} onChange={(e) => props.setSecondaryGrouping(e === '-' ? null : (e as ReportGroupingAxis))}/>
    {props.secondaryGrouping !== null && <>
      <Select label={'En op...'} options={{
        '-': 'Geen',
        ...((props.primaryGrouping === 'customer' || props.secondaryGrouping === 'customer') ? {} : {'customer': 'Klant'}),
        ...((props.primaryGrouping === 'team' || props.secondaryGrouping === 'team') ? {} : {'team': 'Team'}),
        ...((props.primaryGrouping === 'contract' || props.secondaryGrouping === 'contract') ? {} : {'contract': 'Contract'}),
        ...((props.primaryGrouping === 'category' || props.secondaryGrouping === 'category') ? {} : {'category': 'Tijd Categorie'}),
        ...((props.primaryGrouping === 'user' || props.secondaryGrouping === 'user') ? {} : {'user': 'Gebruiker'}),
        ...((props.primaryGrouping === 'ticket' || props.secondaryGrouping === 'ticket') ? {} : {'ticket': 'Ticket'}),
      }} value={props.tertiaryGrouping === null ? '-' : props.tertiaryGrouping} onChange={(e) => props.setTertiaryGrouping(e === '-' ? null : (e as ReportGroupingAxis))}/>
    </>}
    <label className={"flex-col space-y-3 col-start-6 items-center"}>
      <div>round up/down</div>
      <Toggle value={props.roundTotalTime} onChange={props.setRoundTotalTime} />
    </label>
  </div>
}

const ReportSearchFilter: FC<{
  customer: Customer[], customerFilter: string[], setCustomerFilter: Dispatch<SetStateAction<string[]>>,
  status: TicketStatus[], statusFilter: string[], setStatusFilter: Dispatch<SetStateAction<string[]>>,
  teams: Team[], teamsFilter: string[], setTeamsFilter: Dispatch<SetStateAction<string[]>>,
  user: User[], userFilter: string[], setUserFilter: Dispatch<SetStateAction<string[]>>,
  categories: TicketTimeCategory[], categoriesFilter: string[], setCategoriesFilter: Dispatch<SetStateAction<string[]>>,
  contract: Contract[], contractFilter: string[], setContractFilter: Dispatch<SetStateAction<string[]>>,
  from: Moment, setFrom: Dispatch<SetStateAction<Moment>>, to: Moment, setTo: Dispatch<SetStateAction<Moment>>,
  includeContracts: boolean, setIncludeContracts: Dispatch<SetStateAction<boolean>>,
}> = (props) => {
  const refactoredCustomers = props.customer.map((item) => [item.id, {title: item.name}])
  const refactoredStatuses = props.status.map((item) => [item.id, {title: item.status}])
  const refactoredTeams = props.teams.map((item) => [item.id, {title: item.team_name}])
  const refactoredUsers = props.user.map((item) => [item.id, {title: item.name}])
  const refactoredCategories = props.categories.map((item) => {
    return [
      item.id,
      {
        title: item.name,
        icon: item.isBillable ? faDollarCircle : faCircle
      },
    ];
  })
  const [from, setFrom] = useState<Date|null>(null)
  const [to, setTo] = useState<Date|null>(null)
  return <div
    className={"border dark:border-zinc-600 dark:bg-zinc-700 px-3 py-2 rounded grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-5 gap-3"}>
    <div className={''}>
      <Input type={'date'} label={'Van'} value={from ?? props.from.toDate()} onChange={(d) => setFrom(d)}/>
    </div>
    <div className={''}>
      <Input type={'date'} label={'Tot'} value={to ?? props.to.toDate()} onChange={(d) => setTo(d)}/>
    </div>
    <div className={'col-span-3 flex items-end'}>
      <Button type={'primary'} size={'md'} text={'Ophalen'} icon={faRefresh} disabled={!to && !from} onClick={() => {
        if (from) props.setFrom(moment(from))
        if (to) props.setTo(moment(to))
        setFrom(null)
        setTo(null)
      }} />
    </div>
    <div className={""}>
      <ReportDropdown type={"primary"} text={"Klant"} size={"md"} data={Object.fromEntries(refactoredCustomers)}
                      icon={faBuilding} searchText={"Zoek op klanten..."} state={props.customerFilter}
                      stateSetter={props.setCustomerFilter}/>
    </div>
    <div className={""}>
      <ReportDropdown type={"primary"} text={"Status"} size={"md"} data={Object.fromEntries(refactoredStatuses)}
                      icon={faCheckCircle} searchText={"Zoek op statussen..."} state={props.statusFilter}
                      stateSetter={props.setStatusFilter}/>
    </div>
    <div className={""}>
      <ReportDropdown type={"primary"} text={"Post"} size={"md"} data={Object.fromEntries(refactoredCategories)}
                      icon={faDollarSign} searchText={"Zoek op posten..."} state={props.categoriesFilter} stateSetter={props.setCategoriesFilter}
                      footer={<label className={"flex space-x-3 px-3 py-2 items-center"}>
                        <Toggle value={props.includeContracts} onChange={(e) => props.setIncludeContracts(e)} />
                        <div className={"text-sm"}>Inclusief contracten</div>
                      </label>} />
    </div>
    <div className={""}>
      <ReportDropdown type={"primary"} text={"Gebruikers"} size={"md"} data={Object.fromEntries(refactoredUsers)}
                      icon={faUser} searchText={"Zoek op gebruikers..."} state={props.userFilter}
                      stateSetter={props.setUserFilter}/>
    </div>
    <div className={""}>
      <ReportDropdown type={"primary"} text={"Team"} size={"md"} data={Object.fromEntries(refactoredTeams)}
                      icon={faUserGroup} searchText={"Zoek op teams..."} state={props.teamsFilter}
                      stateSetter={props.setTeamsFilter}/>
    </div>
  </div>
}


interface ReportDropdownProps {
  type: "primary" | "secondary" | "danger"
  size: "xs" | "sm" | "md" | "lg"
  text: string
  icon?: fa.IconDefinition
  state: string[]
  stateSetter: Dispatch<SetStateAction<string[]>>
  disabled?: boolean
  onClick?: () => void
  footer?: ReactNode
}

const ReportDropdown: FC<Omit<ReportDropdownProps, "onClick">&{data: {[key: string]: { title: string, icon?: IconDefinition }}, searchText: string}>= (props) => {
  const [filter, setFilter] = useState('')
  const filteredData  = Object.fromEntries(Object.entries(props.data).filter(x => {
    if (filter.trim() === "")
      return true
    return x[1].title.toLowerCase().includes(filter.toLowerCase())
  }).sort(([a, valueA], [b, valueB]) => {
    const inA = props.state.includes(a);
    const inB = props.state.includes(b);
    if (inA && inB) return a.localeCompare(b)
    if (inA) return -1
    if (inB) return 1
    return valueA.title.localeCompare(valueB.title)
  }))
  const height: string = {
    "xs": "h-6",
    "sm": "h-6",
    "md": "h-8",
    "lg": "h-16"
  }[props.size]
  const textStyle: string = {
    "xs": "text-xs font-medium",
    "sm": "text-sm font-medium",
    "md": "text-base font-medium",
    "lg": "text-lg",
  }[props.size]
  const iconStyle: string = {
    "xs": "mr-2",
    "sm": "px-1 w-4",
    "md": "px-3 w-4",
    "lg": "px-3 w-4",
  }[props.size]
  const type: string = {
    "primary": "bg-brand-900 dark:bg-brand-700 hover:bg-brand-800 text-white",
    "secondary": "bg-brand-50 dark:bg-brand-700 text-brand-800 dark:text-brand-200 hover:bg-brand-100 hover:dark:bg-brand-600",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
    "ticket-table": "bg-transparent",
  }[props.type]
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectAll = () => {
    const allKeys = Object.keys(filteredData);
    props.stateSetter(allKeys);
  };

  const unselectAll = () => {
    props.stateSetter([]);
  };

  const updateSelection = async (key: string) => {
    if (props.state.includes(key)) {
      props.stateSetter(props.state.filter(x => x != key))
    }
    if (!props.state.includes(key)) {
      props.stateSetter([...props.state, key])
    }
  }

  return <div ref={dropdownRef} className={`relative flex items-center border border-brand-400 dark:border-brand-500 justify-center ${height} ${textStyle} ${type} ${isCollapsed ? 'rounded' : 'rounded-t border-b-transparent shadow-lg'} hover:cursor-pointer`}
  onClick={() => setIsCollapsed(x => !x)}>
    <FontAwesomeIcon className={`mx-1 w-4`} icon={props.icon!}/>
    {props.text}
    {(props.state.length > 0) && <div className={"rounded-full text-xs px-2 ml-2 dark:bg-zinc-700 dark:text-white"}>{props.state.length}</div>}
    <FontAwesomeIcon className={`px-2 text-xs`} icon={isCollapsed ? faChevronDown : faChevronUp}/>

    {/*DropDown*/}
    {!isCollapsed && <div className={"fixed z-10 insets-0"} onClick={() => setIsCollapsed(false)}></div>}
    {!isCollapsed && <div
      className={"absolute min-w-[calc(100%+10px)] top-full left-0 bg-slate-100 dark:bg-zinc-700 shadow-lg border border-brand-400 dark:border-brand-500 -mx-[1px] rounded-tr rounded-b z-20"}
      onClick={() => {
        setIsCollapsed(true)
      }}>

      <label
        className={"flex-1 border border-b-brand-500 border-transparent flex items-center group dark:bg-zinc-700 focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-2 py-1"}>
        <FontAwesomeIcon icon={faSearch} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
        <input
          type="text"
          placeholder={props.searchText}
          className={"h-8 flex-1 outline-none dark:bg-zinc-700"}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        {filter.length > 0 && <button
          className={"h-8 w-8 flex items-center justify-center text-slate-400 dark:text-zinc-300 hover:text-brand-600 hover:dark:text-brand-500"}
          onClick={() => setFilter('')}>
          <FontAwesomeIcon icon={faTimesCircle}/>
        </button>}
      </label>

      <div className={"overflow-y-scroll max-h-80"}>
        {Object.entries(filteredData).map(([key, value]) => {
          return <label key={key}
                        className={"flex items-center my-1 hover:bg-slate-200 hover:dark:bg-zinc-600 px-2 py-1"}>
            <input className={"mr-2 accent-brand-700"} checked={props.state.includes(key)} type={'checkbox'} onChange={() => updateSelection(key)}/>
            {value.icon && <div className={'h-8 w-8 flex items-center justify-center'}>
              <FontAwesomeIcon icon={value.icon} />
            </div>}
            <div className={`whitespace-nowrap ${value.title === props.text && 'opacity-50'}`}>{value.title}</div>
          </label>
        })}
      </div>
      <div className={"flex-col border-t border-brand-500"}>
        <div className={"flex px-4 py-2 space-x-3 "}>
          <Button type={'secondary'} size={'xs'} text={'Alle'} onClick={selectAll} />
          <Button type={'secondary'} size={'xs'} text={'Geen'} onClick={unselectAll} />
        </div>
        {props.footer ? props.footer : ''}
      </div>
    </div>}
  </div>
}