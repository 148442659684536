import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/pro-solid-svg-icons";
import React, {FC, useState} from "react";
import {Ticket} from "../../api/dto";
import {faCircleNotch, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faVectorPolygon} from "@fortawesome/pro-regular-svg-icons";
import {useApiCall} from "../../api/api";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../../tenant/TenantContext";

interface TicketContextMenuProps {
  ticket: Ticket
}

export const TicketContextMenu: FC<TicketContextMenuProps> = props => {
  const [expanded, setExpanded] = useState(false)
  const {createTicketTemplate, updateTicketTemplate} = useApiCall()
  const {tenant} = useTenant()
  const navigate = useNavigate()
  return <button className={'h-8 w-8 flex items-center justify-center hover:bg-slate-100 dark:hover:bg-zinc-600 rounded relative'} onClick={() => {setExpanded(!expanded)}}>
    <FontAwesomeIcon icon={faEllipsisV} className={'text-slate-600 dark:text-zinc-300'}/>
    {expanded && <div className={'z-10 fixed inset-0 bg-[#0002]'} onClick={() => {setExpanded(false)}}></div>}
    {expanded && <div className={'z-20 absolute top-8 right-0 min-w-64 min-h-12 py-2 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded text-left'}>

      {/* Options: */}
      <TicketContextMenuOption close={() => setExpanded(false)} icon={faVectorPolygon} text={'Maak template'} onClick={async () => {
        const template = await createTicketTemplate(props.ticket.subject ?? '??', props.ticket.getType(), props.ticket.subject ?? '??', null, props.ticket.description, props.ticket.priority_id, props.ticket.customer_id)
        if (props.ticket.tasks.length > 0 || props.ticket.team_id !== null) {
          await updateTicketTemplate(template.id, {
            tasks: props.ticket.tasks.map(t => t.description),
            team_uuid: props.ticket.team_id,
          })
        }
        navigate(`/${tenant}/ticket-templates/${template.id}`)
      }} />

    </div>}
  </button>
}

interface TicketContextMenuOptionProps {
  icon: IconDefinition
  text: string
  onClick: () => void|Promise<void>
  close: () => void
}
const TicketContextMenuOption: FC<TicketContextMenuOptionProps> = props => {
  const [isLoading ,setIsLoading] = useState(false)
  const onClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (isLoading) return
    setIsLoading(true)
    await props.onClick()
    setIsLoading(false)
    props.close()
  }
  return <div className={`flex items-center h-10 ${isLoading ? 'cursor-default opacity-50' : 'cursor-pointer'} hover:bg-slate-100 dark:hover:bg-zinc-600`} onClick={(e) => onClick(e)}>
    <div className={'h-10 w-10 flex items-center justify-center'}>
      <FontAwesomeIcon icon={isLoading ? faCircleNotch : props.icon} className={'text-slate-600 dark:text-zinc-300'} spin={isLoading} />
    </div>
    <div className={'flex-1 font-medium text-sm'}>
      {props.text}
    </div>
  </div>
}
