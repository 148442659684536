import React, {FC, useEffect, useMemo, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd, faCookieBite,
  faFilter,
  faInbox, faTicket,
} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {Button} from "../components/form/Button";
import {AddTicketModal} from "../modals/AddTicketModal";
import {useApiCall} from "../api/api";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, TicketTemplate, User} from "../api/dto";
import {useApi, useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {SimplePaginate} from "../components/data/SimplePaginate";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchControlBar} from "../components/tickets/SearchControlBar";
import {useTenant} from "../tenant/TenantContext";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {AddTicketTemplateModal} from "../modals/AddTicketTemplateModal";
import {useNavigate} from "react-router-dom";

export const TicketTemplates: FC = () => {
  const {getTicketTemplates, getAllPriorities, getCustomers, getTeams} = useApiCall()
  const {resource: ticketTemplates, loading: loadTicketTemplates, reload: reloadTicketTemplates} = useFetchedResource(() => getTicketTemplates())
  const {resource: ticketPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {tenant} = useTenant()

  const navigate = useNavigate()

  useRefreshEffect(() => {
    reloadTicketTemplates(undefined)
    reloadPriorities(undefined)
    reloadCustomers(undefined)
    reloadTeams(undefined)
  })

  const {open: openCreateModal} = useModal({title: 'Template toevoegen', body: <AddTicketTemplateModal priorities={ticketPriorities ?? []} customers={customers ?? []} />, size: 'lg'})

  const customerMap = useMemo(() => {
    return Object.fromEntries((customers ?? []).map((c: Customer) => [c.id, c]))
  }, [customers])
  const teamMap = useMemo(() => {
    return Object.fromEntries((teams ?? []).map((t: Team) => [t.id, t]))
  }, [teams])

  return (
    <ContentContainer size={'xl'}>
      <div className={"flex justify-between items-end"}>
        <PageIconHeader icon={faInbox}>
          <Breadcrumbs crumbs={[
            {label: "Servicedesk", href: `/${tenant}/ticket`},
          ]} currentPage={"Ticket Templates"} />
          <PageHeader>Tickets</PageHeader>
        </PageIconHeader>
        <div className={"flex space-x-4 mb-8"}>
          <Button size={"md"} type={"primary"} text={"Template toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
        </div>
      </div>

      {loadTicketTemplates ? <>
        Loading templates...
      </> : <>
        <SearchPaginateReload data={ticketTemplates??[]} filterProperties={['name']} resultsBuilder={(subSet) => {
          return <DataTable
            data={subSet}
            keyProperty={'id'}
            placeholder={'Geen ticket templates gevonden'}
            columns={[
              {header: 'Naam', property: 'name'},
              {header: 'Klant', property: 'customerId', transform: (id: string|null) => <>{customerMap[id ?? '-']?.name ?? '-'}</>},
              {header: 'Team', property: 'teamId', transform: (id: string|null) => <>{teamMap[id ?? '-']?.team_name ?? '-'}</>},
            ]}
            onClickRow={(template: TicketTemplate) => {navigate(`/${tenant}/ticket-templates/${template.id}`)}}
          />
        }} />
      </>}

    </ContentContainer>
  );
}
