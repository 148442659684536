import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Toggle} from "../components/form/Toggle";
import {Sla, TicketStatus} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "../components/form/Button";
import {faTrash, faX} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";

export const DeleteSlaModal: FC<{sla: Sla}> = (props) => {
  const {deleteSla} = useApiCall()
  const reload = useRefresh()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteSla(props.sla.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }

  return <>
    <p>
      <strong>Waarschuwing!</strong> Je staat op het punt om <strong>{props.sla.sla_name}</strong> te verwijderen uit jouw omgeving.
    </p>
    <InputErrors errors={errors} field={'error'} />
      <ModalFooter icon={faTrash} text={"delete"} onSubmit={confirm}/>
  </>
}