import React, {FC, useEffect} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd,
  faEnvelope,
  faFileContract,
  faLocationPin, faPencil, faPersonDigging,
  faPhone,
  faTrash,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime} from "../components/RefreshController";
import {Customer} from "../api/dto";
import {useParams} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons/faGlobe";
import {Button} from "../components/form/Button";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteCustomerModal} from "../modals/DeleteCustomerModal";
import {usePermission} from "../permissions/PermissionContext";
import {IconAvatar} from "../components/content/IconAvatar";
import {TabContainer} from "../components/layout/TabContainer";
import {CustomerUsersTab} from "../components/customers/details/CustomerUsersTab";
import {CustomerSlaTab} from "../components/customers/details/CustomerSlaTab";

export const CustomerDetails: FC = () => {
  const {getCustomer, getAllPriorities, getSlaForCustomer} = useApiCall()
  const {tenant} = useTenant()
  const {customer} = useParams()
  const {resource: customerDetails, reload: reloadCustomer, loading: loadingCustomer} = useFetchedResource((customer: string) => getCustomer(customer))
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {open: deleteModal} = useModal({title: "Klant verwijderen", body: <DeleteCustomerModal customer={customerDetails!}/>, size: 'md'})
  const latestRefreshTime = useLatestRefreshTime()

  useEffect(() => {
    if (customer === undefined) {
      console.warn('customer uuid is undefined')
      return
    }
    reloadCustomer(customer)
    reloadPriorities(undefined)
  }, [customer, latestRefreshTime])

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader backButton={true} icon={faUser}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
          {label: "Klanten", href: `/${tenant}/customers`}
        ]} currentPage={customerDetails?.name ?? "Klant details"}/>
        <PageHeader>{customerDetails?.name}</PageHeader>
      </PageIconHeader>

      {usePermission().canDeleteCustomers() &&
        <div className={"flex justify-self-end mb-3"}>
          <Button type={"danger"} size={"md"} text={"Verwijderen"} icon={faTrash} onClick={() => deleteModal()}/>
        </div>
      }

      {customerDetails &&
        <CustomerInfo customer={customerDetails}/>
      }

      <TabContainer
        tabs={[
          // TODO: add more information to customerDetailsTab before uncommenting
          // {name: 'Details', body: <CustomerDetailsTab />},

          ...((customerDetails && customer) ? [
            {name: 'Gebruikers', body: <CustomerUsersTab customer={customerDetails} users={customerDetails?.users ?? []} reload={() => reloadCustomer(customer)}/>}
            ] : []),

          ...((usePermission().canReadSLA() && customerDetails && ticketPriorities) ? [
            {name: 'SLA', body: <CustomerSlaTab priorities={ticketPriorities} customer={customerDetails} />}
          ] : [])
        ]}
      />

    </ContentContainer>
  )
}

const CustomerInfo: FC<{ customer: Customer }> = (props) => {
  return <div className={"rounded justify-center items-center dark:bg-zinc-700 mb-5"}>
    <div className={"px-2 py-1 rounded border border-slate-200 dark:border-zinc-600 shadow space-y-3"}>
      <h1 className={'px-2 py-1 text-lg'}>Contactpersoon</h1>
      <div className="flex mr-5 space-x-3 items-center">
        <IconAvatar icon={faUser}/>
        <dl>
          <dt className="text-sm font-medium">Naam</dt>
          <dd className="text-brand-900 dark:text-brand-200">{props.customer.name}</dd>
        </dl>
      </div>

      {/* Customer address */}
      {props.customer.address && (
        <div className="flex mr-5 space-x-3 items-center">
          <IconAvatar icon={faLocationPin}/>
          <dl>
            <dt className="text-sm font-medium">Adres</dt>
            <dd className="text-brand-900 dark:text-brand-200">{props.customer.contact_person}</dd>
          </dl>
        </div>
      )}

      {/* Customer email */}
      <div className={"flex flex-wrap"}>
        {props.customer.email && (
          <div className="flex mr-5 space-x-3 items-center">
            <IconAvatar icon={faEnvelope}/>
            <dl>
              <dt className="text-sm font-medium">Email</dt>
              <dd className="text-brand-900 dark:text-brand-200">{props.customer.email}</dd>
            </dl>
          </div>
        )}

        {/* Customer phone */}
        {props.customer.phone &&
          <div
            className={"flex items-center justify-center space-x-1 h-8 px-2 text-sm font-medium bg-transparent hover:bg-brand-100 hover:dark:bg-brand-600 text-brand-600 hover:text-brand-700 hover:dark:text-brand-200 rounded hover:cursor-pointer mr-2"}
            onClick={() => window.location.href = `tel:${props.customer.phone}`}>
            <FontAwesomeIcon icon={faPhone}/>
            <h1>{props.customer.phone ? props.customer.phone : "Niet bekend"}</h1>
          </div>
        }

        {/* Customer web url */}
        {props.customer.host &&
          <div
            className={"flex items-center justify-center space-x-1 h-8 px-2 text-sm font-medium bg-transparent hover:bg-brand-100 hover:dark:bg-brand-600 text-brand-600 hover:text-brand-700 hover:dark:text-brand-200 rounded hover:cursor-pointer mr-2"}
            onClick={() => window.location.href = `https://${props.customer.host}`}>
            <FontAwesomeIcon icon={faGlobe}/>
            <h1>{props.customer.host ? props.customer.host : "Niet bekend"}</h1>
          </div>
        }
      </div>
    </div>
  </div>
}