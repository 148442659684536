import {Module, Tenant} from "../api/dto";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface NavigationOptions {
  requiredModule?: Module|Module[],
  requiredPermission?: string|string[],
}
export class NavigationHeader {
  constructor(public readonly title: string, public readonly options?: NavigationOptions) {
  }
}
export class NavigationItem {
  constructor(public readonly title: string, public readonly icon: IconDefinition, public readonly link: string|((tenant: Tenant) => string), public readonly options?: NavigationOptions) {
  }

  getLink(tenant: Tenant): string {
    return typeof this.link === 'string' ? this.link : this.link(tenant)
  }
}

export const Navigation: FC<{tenant?: Tenant|null, isSidebarCollapsed: boolean, items: Array<NavigationHeader|NavigationItem>}> = props => {
  if (props.tenant === undefined || props.tenant === null) {
    return <></>
  }
  const items = props.items
    .filter((item) => {
      return hasPermissionOrModuleIfRequired(item.options?.requiredModule, props.tenant!.modules)
          && hasPermissionOrModuleIfRequired(item.options?.requiredPermission, props.tenant!.permissions)
    })
  const linkStyle = (props: {isActive: boolean, isPending: boolean}) => `h-12 flex items-center rounded-lg ${props.isActive ? 'bg-brand-100 dark:bg-brand-700 text-brand-800 dark:text-brand-200' : 'text-slate-600 dark:text-zinc-300 hover:text-brand-800 hover:dark:text-brand-200 hover:bg-brand-100 hover:dark:bg-brand-700'} px-5 font-medium mb-1`
  return <>{items.map((item, i) => {
    if (item instanceof NavigationHeader) {
      return <div
        className={`mt-8 mb-2 flex items-center px-4 overflow-hidden whitespace-nowrap uppercase text-brand-700 text-sm font-semibold ${props.isSidebarCollapsed ? 'collapse' : ''}`}
        key={i}
      >{item.title}</div>
    }
    return <NavLink to={item.getLink(props.tenant!)} className={linkStyle} key={i}>
                <span className={`w-5 text-center`}>
                  <FontAwesomeIcon icon={item.icon}/>
                </span>
      {!props.isSidebarCollapsed && <span className={"flex-1 ml-3"}>{item.title}</span>}
    </NavLink>;
  })}</>
}

const hasPermissionOrModuleIfRequired = (permissionOrModule: string|Module|Array<string|Module>|undefined, collection: Array<string|Module>|null|undefined): boolean => {
  collection = collection ?? []
  if (permissionOrModule === undefined) {
    return true
  }
  if (Array.isArray(permissionOrModule)) {
    return permissionOrModule.some(value => collection!.includes(value))
  }
  return collection.includes(permissionOrModule)
}