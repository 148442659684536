import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket,faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faGear} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../../util/usePersistentState";
import {useTenant} from "../../tenant/TenantContext";
import {Tenant} from "../../api/dto";
import {useApiCall} from "../../api/api";
import {useApi, useFetchedResource} from "../../api/APIContext";
import {useRefreshEffect} from "../RefreshController";
import keycloak from "../../auth/keycloak";
import {Navigation, NavigationHeader, NavigationItem} from "../Navigation";
import {usePermission} from "../../permissions/PermissionContext";

export const AuthenticatedLayout: FC<{children: React.ReactNode, navigationItems: Array<NavigationHeader|NavigationItem>}> = ({children, navigationItems}) => {
  const {tenant} = useTenant()
  const {currentTenant, tenants: userTenants} = useApi()

  useEffect(() => {
    if (currentTenant) {
      document.title = `Windesk · ${currentTenant.name}`
      document.querySelector('head > link[rel="icon"]')?.remove()
      if (currentTenant.logo) {
        const icon = document.createElement('link');
        icon.rel = 'icon';
        icon.href = currentTenant.logo
        document.querySelector('head')?.appendChild(icon)
      }
    }
  }, [currentTenant]);
  const linkStyle = (props: {isActive: boolean, isPending: boolean}) => `h-12 flex items-center rounded-lg ${props.isActive ? 'bg-brand-100 dark:bg-brand-700 text-brand-800 dark:text-brand-100' : 'text-slate-600 dark:text-zinc-300 hover:text-brand-800 hover:bg-brand-100 hover:dark:bg-brand-700 hover:dark:text-brand-100'} px-5 font-medium mb-1`
  const [isSideCollapsed, setIsSideCollapsed] = usePersistentState<boolean>('collapse_sidebar', false)

  return <div className={"flex flex-col min-h-screen bg-slate-50 dark:bg-zinc-800 dark:text-white"}>
    <div className={"flex-1 flex items-stretch"}>
      <nav className={"flex-1 flex items-stretch"}>
        {tenant && <>
          <aside
            className={`bg-white dark:bg-zinc-800 ${isSideCollapsed ? 'w-20' : 'w-64'} transition-all flex flex-col justify-between border-r border-slate-200 dark:border-zinc-600`}>
            {/* Navigation */}
            <div className={'flex flex-col p-2'}>
              {currentTenant && <>
                <TenantHeader tenant={currentTenant} tenants={userTenants??[]} sidebarCollapsed={isSideCollapsed}/>
              </>}
              <Navigation isSidebarCollapsed={isSideCollapsed} items={navigationItems} tenant={currentTenant}/>
            </div>
            <button
              className={"h-10 py-6 flex items-center justify-center px-4 overflow-hidden whitespace-nowrap text-lg font-medium text-gray-600 hover:bg-brand-100 dark:text-zinc-300 hover:dark:bg-brand-700"}
              onClick={() => setIsSideCollapsed(x => !x)}>
              <div>
                <FontAwesomeIcon icon={isSideCollapsed ? faChevronRight : faChevronLeft}/>
              </div>
            </button>
          </aside>
        </>}
        <main className={"flex-1 overflow-y-scroll py-4"} style={{maxHeight: "100vh"}}>
          <div className={"mx-8 my-8"}>
              {children}
          </div>
        </main>
      </nav>
    </div>
  </div>
}

const TenantHeader: FC<{ tenant: Tenant, tenants: Tenant[], sidebarCollapsed: boolean}> = (props) => {
  const navigate = useNavigate();
  const {getTenantLogoURL} = useApiCall();
  const {setColorPalette} = useTenant()

  useEffect(() => {
    setColorPalette(props.tenant.color_palette)
  }, [props.tenant]);

  const [isTenantCollapsed, setIsTenantCollapsed] = useState<boolean>(true)

  const getDefaultRoute = (tenant: Tenant) => {
    if (tenant.modules.length === 1) {
      if (tenant.modules[0] === 'crm') {
        return `${tenant.slug}/relations`
      } else if (tenant.modules[0] === 'service_desk') {
        return `${tenant.slug}/ticket`
      }
    }
    return `${tenant.slug}/dashboard`
  }

  return <>
    {/* Tenant header */}
    <div
      className={`h-16 flex items-center gap-2 rounded ${isTenantCollapsed ? 'hover:bg-brand-100 hover:dark:bg-brand-700' : ''} -mx-1 px-1 cursor-pointer mb-6 relative`}
      onClick={() => setIsTenantCollapsed(x => !x)}>
      <button></button>

      {/*//TODO: Currently it loops over all available tenants*/}
      {/*// Make it so that it also loop over the available customers and display them*/}
      {/*// preferably with the role the user has within that environment*/}
      {/*// (e.g. Windesk developer - Admin)*/}

      {props.tenant.logo ? <img className={`flex h-12 z-10 w-12 col-span-2 bg-slate-300 dark:bg-zinc-300 rounded`} src={getTenantLogoURL()} alt={""}/> : <div className={`flex h-12 z-10 w-12 col-span-2 bg-slate-300 dark:bg-zinc-300 rounded`}/>}

      <div className={`flex-1 z-10 flex flex-col items-start justify-center ${props.sidebarCollapsed ? 'hidden' : ''}`}>
        <div className={`${props.tenant.name.length > 21 ? 'text-xs' : (props.tenant.name.length > 16 ? 'text-sm' : 'text-md')} font-semibold text-slate-600 dark:text-zinc-300 whitespace-nowrap`}>
          {props.tenant.name}
        </div>

        <div
          className={"text-sm text-slate-500 dark:text-zinc-300 flex items-center space-x-1"}>
          <FontAwesomeIcon icon={faGear} className={'text-xs'}/><span>Instellingen</span>
        </div>
      </div>

      <div className={`flex z-10 justify-center items-center text-slate-600 dark:text-zinc-300 ${props.sidebarCollapsed ? 'hidden' : ''}`}>
        <FontAwesomeIcon icon={isTenantCollapsed ? faChevronDown : faChevronUp}/>
      </div>

      {/* Dropdown */}
      {!isTenantCollapsed && <div
        className={"bg-white dark:bg-zinc-700 text-slate-800 dark:text-zinc-300 rounded shadow-xl absolute top-full left-0 min-w-full flex flex-col border border-slate-300 dark:border-zinc-600 px-2 py-1"}>
        <div className={"fixed inset-0 h-[100vh] w-[100vw]"} onClick={(e) => {
          setIsTenantCollapsed(false)
        }}></div>
        {props.tenants.map((tenant, i) => {
          return <button key={i}
                         className={"flex z-10 items-center gap-3 my-1 py-1 px-1 rounded hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-800 min-w-full"}
                         onClick={() => {
                           navigate(getDefaultRoute(tenant));
                           navigate(0);
                         }}>
            {tenant.logo ? <img className={`h-10 w-10 col-span-2 bg-slate-300 dark:bg-zinc-300 rounded`} alt={""}
                                src={getTenantLogoURL(tenant.slug)}/> :
              <div className={`h-10 w-10 col-span-2 bg-slate-300 dark:bg-zinc-300 rounded`}/>}
            <div
              className={`flex-1 flex flex-col items-start justify-center`}>
              <div
                className={"text-md font-medium text-slate-900 dark:text-zinc-300 whitespace-nowrap"}>
                {tenant.name}
              </div>
              <div
                className={"text-xs font-medium text-slate-500 dark:text-zinc-50"}>
                {tenant.role}
              </div>
            </div>
          </button>
        })}

        {props.tenant.permissions.some(p => p.startsWith('tenant:edit')) &&
          <button
            className={"flex z-10 items-center gap-3 my-1 py-1 px-1 rounded hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-800 hover:dark:text-zinc-300 min-w-full"}
            onClick={() => navigate(`/${props.tenant.slug}/settings`)}>
            <div
              className={`h-10 w-10 bg-brand-200 dark:bg-brand-700 text-brand-800 dark:text-brand-200 col-span-2 rounded flex justify-center items-center`}>
              <FontAwesomeIcon className={'h-4 w-4'} icon={faGear}/>
            </div>
            <div
              className={`flex-1 flex flex-col items-start justify-center`}>
              <div
                className={"text-md font-medium text-slate-900 dark:text-zinc-300 whitespace-nowrap"}>
                Organisatie instellingen
              </div>
            </div>
          </button>
        }

        <button
          className={"flex z-10 items-center gap-3 my-1 py-1 px-1 rounded hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-800 hover:dark:text-zinc-300 min-w-full"}
          onClick={() => navigate(`/user-settings`)}>
          <div
            className={`h-10 w-10 bg-brand-200 dark:bg-brand-700 text-brand-800 dark:text-brand-200 col-span-2 rounded flex justify-center items-center`}>
            <FontAwesomeIcon className={'h-4 w-4'} icon={faGear}/>
          </div>
          <div
            className={`flex-1 flex flex-col items-start justify-center`}>
            <div
              className={"text-md font-medium text-slate-900 dark:text-zinc-300"}>
              Profiel
            </div>
          </div>
        </button>

        <button
          className={"flex z-10 items-center gap-3 my-1 py-1 px-1 rounded hover:bg-brand-100 hover:dark:bg-brand-700 hover:text-brand-800 hover:dark:text-zinc-300 min-w-full"}
          onClick={() => keycloak.logout()}>
          <div
            className={`h-10 w-10 bg-brand-200 dark:bg-brand-700 text-brand-800 dark:text-brand-200 col-span-2 rounded flex justify-center items-center`}>
            <FontAwesomeIcon className={'h-4 w-4'} icon={faArrowRightFromBracket}/>
          </div>
          <div
            className={`flex-1 flex flex-col items-start justify-center`}>
            <div
              className={"text-md font-medium text-slate-900 dark:text-zinc-300"}>
              Uitloggen
            </div>
          </div>
        </button>

      </div>
      }
    </div>
  </>
}
