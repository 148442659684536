import React, {FC} from "react";
import {DashboardReport} from "../../api/dto";
import {useLocalSettings} from "../LocalSettingsContext";
import {SectionHeader} from "../content/SectionHeader";
import {Card} from "../Card";
import {Button} from "../form/Button";
import {Input} from "../form/Input";
import {formatTimeString} from "../../util/formatTimeString";

export const WeeklyHoursGraph: FC<{report: DashboardReport}> = props => {
  const {setWeeklyBillableTarget, weeklyBillableTarget} = useLocalSettings();
  if (weeklyBillableTarget === null) {
    return <Setup />
  }
  const timeToGo = weeklyBillableTarget - props.report.billableHoursWrittenThisWeek;
  return <Card title={'Weekdoelen'}>
    <div className={'flex'}>
      <div className={'flex-1 mr-3'}>
        <CursedGraph target={weeklyBillableTarget} report={props.report} />
      </div>
      <div
        className={'h-64 w-64 border border-slate-100 dark:border-zinc-600 rounded-lg flex flex-col items-center justify-center'}>
        <span className={"text-4xl font-bold mb-2 text-black dark:text-white"}>
          {timeToGo > 0 ? formatTimeString(timeToGo) : '🎉'}
        </span>
        <h2 className={"font-medium text-sm text-center"}>
          {timeToGo > 0 ? 'Billable uren nodig' : 'Goed bezig!'}<br/>
          <span className={'opacity-75 text-xs'}>{timeToGo > 0 ? 'om je weekdoel te halen' : 'Je hebt je weekdoel gehaald'}</span>
        </h2>
      </div>
    </div>
    <Button type={'secondary'} size={'sm'} text={'Weekdoelen aanpassen'} onClick={() => setWeeklyBillableTarget(null)}/>
  </Card>
}

const Setup: FC = props => {
  const {weeklyBillableTarget, setWeeklyBillableTarget} = useLocalSettings();
  const [input, setInput] = React.useState(weeklyBillableTarget??0);
  return <Card title={'Weekdoelen'}>
    <div className={'mb-3'}>
      <strong>Jouw weekdoelen instellen</strong>
    </div>
    <div className={'flex justify-start items-end space-x-3'}>
      <Input type={'time'} value={input} onChange={setInput} label={'Billable uren per week'} />
      <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={() => setWeeklyBillableTarget(input)} />
    </div>
  </Card>
}

const CursedGraph: FC<{target: number, report: DashboardReport}> = props => {
  const dayTarget = props.target / 5
  const {
    billableHoursPerDayMonday: mon, billableHoursPerDayTuesday: tue, billableHoursPerDayWednesday: wed, billableHoursPerDayThursday: thu, billableHoursPerDayFriday: fri,
    unBillableHoursPerDayMonday: monU, unBillableHoursPerDayTuesday: tueU, unBillableHoursPerDayWednesday: wedU, unBillableHoursPerDayThursday: thuU, unBillableHoursPerDayFriday: friU
  } = props.report;
  const maxY = Math.max(dayTarget, mon + monU, tue + monU, wed + monU, thu + monU, fri + monU);
  console.log(dayTarget, mon , monU, tue , monU, wed , monU, thu , monU, fri , monU);
  return <div className={'h-64 border border-slate-100 dark:border-zinc-600 rounded-lg mb-3 flex flex-col items-stretch'}>
    <div className={'flex-1 grid grid-cols-5 relative'}>
      {props.target > 0 && <div className={'absolute left-0 w-full px-2 z-0 text-xs border-b border-slate-400 dark:border-zinc-500'} style={{bottom: `${(dayTarget / maxY) * 100}%`}}>
        {formatTimeString(dayTarget)}
      </div>}
      <div className={'flex flex-col justify-end items-center'}>
        <div className={`w-1/2 bg-slate-300 dark:bg-zinc-600 text-opacity-50 z-10 rounded flex flex-col items-center text-white`}
             style={{height: `${(monU / maxY) * 100}%`}}>
          {monU < 60 ? '' : formatTimeString(monU)}
        </div>
        <div className={`w-1/2 ${mon < dayTarget ? 'bg-red-400 dark:bg-red-800' : 'bg-green-300 dark:bg-green-700'} z-10 rounded-t flex flex-col items-center text-white`}
             style={{height: `${(mon / maxY) * 100}%`}}>
          {mon < 60 ? '' : formatTimeString(mon)}
        </div>
      </div>
      <div className={'flex flex-col justify-end items-center'}>
        <div className={`w-1/2 bg-slate-300 dark:bg-zinc-600 text-opacity-50 z-10 rounded flex flex-col items-center text-white`}
             style={{height: `${(tueU / maxY) * 100}%`}}>
          {tueU < 60 ? '' : formatTimeString(tueU)}
        </div>
        <div className={`w-1/2 ${tue < dayTarget ? 'bg-red-400 dark:bg-red-800' : 'bg-green-300 dark:bg-green-700'} z-10 rounded-t flex flex-col items-center text-white`}
             style={{height: `${(tue / maxY) * 100}%`}}>
          {tue < 60 ? '' : formatTimeString(tue)}
        </div>
      </div>
      <div className={'flex flex-col justify-end items-center'}>
        <div className={`w-1/2 bg-slate-300 dark:bg-zinc-600 text-opacity-50 z-10 rounded flex flex-col items-center text-white`}
             style={{height: `${(wedU / maxY) * 100}%`}}>
          {wedU < 60 ? '' : formatTimeString(wedU)}
        </div>
        <div className={`w-1/2 ${wed < dayTarget ? 'bg-red-400 dark:bg-red-800' : 'bg-green-300 dark:bg-green-700'} z-10 rounded-t flex flex-col items-center text-white`}
             style={{height: `${(wed / maxY) * 100}%`}}>
          {wed < 60 ? '' : formatTimeString(wed)}
        </div>
      </div>
      <div className={'flex flex-col justify-end items-center'}>
        <div className={`w-1/2 bg-slate-300 dark:bg-zinc-600 text-opacity-50 z-10 rounded flex flex-col items-center text-white`}
             style={{height: `${(thuU / maxY) * 100}%`}}>
          {thuU < 60 ? '' : formatTimeString(thuU)}
        </div>
        <div className={`w-1/2 ${thu < dayTarget ? 'bg-red-400 dark:bg-red-800' : 'bg-green-300 dark:bg-green-700'} z-10 rounded-t flex flex-col items-center text-white`}
             style={{height: `${(thu / maxY) * 100}%`}}>
          {thu < 60 ? '' : formatTimeString(thu)}
        </div>
      </div>
      <div className={'flex flex-col justify-end items-center'}>
        <div className={`w-1/2 bg-slate-300 dark:bg-zinc-600 text-opacity-50 z-10 rounded flex flex-col items-center text-white`}
             style={{height: `${(friU / maxY) * 100}%`}}>
          {friU < 60 ? '' : formatTimeString(friU)}
        </div>
        <div className={`w-1/2 ${fri < dayTarget ? 'bg-red-400 dark:bg-red-800' : 'bg-green-300 dark:bg-green-700'} z-10 rounded-t flex flex-col items-center text-white`}
             style={{height: `${(fri / maxY) * 100}%`}}>
          {fri < 60 ? '' : formatTimeString(fri)}
        </div>
      </div>
    </div>
    <div className={'h-8 border-t border border-slate-100 dark:border-zinc-600 grid grid-cols-5'}>
      <div className={'flex items-center justify-center font-medium text-sm'}>Ma</div>
      <div className={'flex items-center justify-center font-medium text-sm'}>Di</div>
      <div className={'flex items-center justify-center font-medium text-sm'}>Wo</div>
      <div className={'flex items-center justify-center font-medium text-sm'}>Do</div>
      <div className={'flex items-center justify-center font-medium text-sm'}>Vr</div>
    </div>
  </div>
}