import React, {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faUser} from "@fortawesome/free-solid-svg-icons";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Callout} from "../components/content/Callout";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {Customer} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {useTenant} from "../tenant/TenantContext";
import {Button} from "../components/form/Button";
import {useModal} from "../components/layout/ModalProvider";
import {AddCustomerModal} from "../modals/AddCustomerModal";
import {usePermission} from "../permissions/PermissionContext";

export const Customers: FC = () => {
  const {getCustomers} = useApiCall()
  const {open: openCreateModal} = useModal({title: 'Klant toevoegen', body: <AddCustomerModal />, size: "md"})
  const {resource: customers, reload: reloadCustomers, loading: loadingCustomers} = useFetchedResource(() => getCustomers())
  const {tenant} = useTenant()

  useRefreshEffect(() => {
    reloadCustomers(undefined)
  })

  const permission = usePermission()

  return (
    <ContentContainer>
      <PageIconHeader icon={faUser}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
        ]} currentPage={"Klanten"} />
        <PageHeader>Klanten</PageHeader>
      </PageIconHeader>

      {permission.canAddCustomers() && <>
        <Callout>
          <SectionHeader>Beheer je klanten</SectionHeader>
          <Paragraph>
            Beheer hier de klanten die toegang hebben tot jouw omgeving
          </Paragraph>
          <Button size={"sm"} type={"primary"} text={"Klant toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
        </Callout>
      </>}


      {loadingCustomers ? <>
        Loading customers...
        </> :
        <CustomerList customers={customers ?? []} reload={() => reloadCustomers(undefined)} />
      }
    </ContentContainer>
  )
}

const CustomerList: FC<{customers: Customer[], reload: () => void}> = (props) => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const filteredCustomers = props.customers.filter(c => c.deletedAt === null).sort((a, b) => a.name.localeCompare(b.name))
  return <SearchPaginateReload data={filteredCustomers} placeholder={'Zoek op naam of klantnummer'} filterProperties={['name', 'referenceNumber']} onReload={() => props.reload()} paginationEnabled={true} resultsBuilder={(filteredCustomers) => {
    return <DataTable
    keyProperty={'id'}
    data={filteredCustomers}
    columns={[
      {
        header: 'Referentie nr.',
        property: 'referenceNumber'
      },
      {
        header: 'Klant',
        property: 'name'
      },
    ]}
    placeholder={<>Nog geen customers</>}
    onClickRow={(customer) => navigate(`/${tenant}/customers/${customer.id}`)}
    />
  }} />
}