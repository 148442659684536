import React, {FC, ReactNode} from "react";
import {useLocalSettings} from "../LocalSettingsContext";
import {InteractiveTimer} from "../time/InteractiveTimer";

export const PageContainer: FC<{children: ReactNode}> = (props) => {
  const {enrolledInTimeBeta} = useLocalSettings()
  return <>
    {enrolledInTimeBeta && <InteractiveTimer />}
    {props.children}
  </>
}