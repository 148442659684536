import React, {FC, useEffect, useMemo} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faFileSignature} from "@fortawesome/pro-regular-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {useTenant} from "../tenant/TenantContext";
import {useModal} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime, useRefresh, useRefreshEffect} from "../components/RefreshController";
import {Contract, contractStage, Ticket, TicketTime} from "../api/dto";
import {AddContractModal} from "../modals/AddContractModal";
import {useNavigate, useParams} from "react-router-dom";
import {Button, IconButton} from "../components/form/Button";
import {EditContractModal} from "../modals/EditContractModal";
import {DeleteContractModal} from "../modals/DeleteContractModal";
import {formatTimeString} from "../util/formatTimeString";
import {SectionHeader} from "../components/content/SectionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faForward} from "@fortawesome/pro-solid-svg-icons";
import {faChevronDown, faChevronRight, faChevronUp, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DataTable} from "../components/data/DataTable";
import moment from "moment";


const contractStageText: {[stage: string]: string} = {
  'Concept': 'Concept',
  'Actief': 'Actief',
  'Afgerond': 'Afgerond',
  'Archief': 'Archief',
}

export const ContractDetails: FC = () => {

  const {contract} = useParams()
  const {getContract, getContractTimes, getAllTicketsForTenant} = useApiCall()
  const {tenant} = useTenant()
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: contractDetails, reload: reloadContract, loading: loadingContract} = useFetchedResource((contract: string) => getContract(contract))
  const {resource: contractTimes, reload: reloadContractTimes, loading: loadingContractTimes} = useFetchedResource((contract: string) => getContractTimes(contract))

  useRefreshEffect(() => {
    if (contract === undefined) {
      console.warn('contract uuid is undefined')
      return
    }
    reloadContract(contract)
    reloadContractTimes(contract)
    reloadTickets(undefined)
  }, [contract, reloadTickets])

  return (
    <ContentContainer>
      <PageIconHeader backButton={true} icon={faFileSignature}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
          {label: "Contracten", href: `/${tenant}/contracts`}
        ]} currentPage={contractDetails?.name ?? "Contract details"}/>
        <PageHeader>{contractDetails?.name}</PageHeader>
      </PageIconHeader>



      {loadingContract ? (
        <>Loading contract...</>
      ) : contractDetails ? (
        <ContractDetailsComponent contract={contractDetails} times={contractTimes??[]}  />
      ) : (
        <>No contract details found.</>
      )}

      {contractTimes && <ContractTimes times={contractTimes} tickets={tickets??[]} />}

    </ContentContainer>
  )
}


const ContractDetailsComponent: FC<{ contract: Contract, times: TicketTime[] }> = (props) => {
  const {getCustomer} = useApiCall()
  const navigate = useNavigate()
  const tenant = useTenant()
  const {open: openEditModal} = useModal({title: 'Contract bewerken', body: <EditContractModal contract={props.contract} />, size: "lg"})
  const {open: openDeleteModal} = useModal({title: 'Contract verwijderen', body: <DeleteContractModal contract={props.contract} />, size: "md"})
  const {resource: customerDetails, reload: reloadCustomer, loading: loadingCustomer} = useFetchedResource((customer: string) => getCustomer(customer))
  useRefreshEffect(() => {
    if (props.contract.customerId === undefined) {
      console.warn('customer uuid is undefined')
      return
    }
    reloadCustomer(props.contract.customerId)
  }, [props.contract.customerId])

  const formatDate = (date: Date): string => {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const totalTime = useMemo(() => {
    let total = 0;
    props.times.forEach((item) => {
      total += item.value|| 0
    });
    return total
  }, [props.times]);


  return <div className={'pb-4'}>
    <div className={"flex space-x-2 justify-end pb-4"}>
      <ContractTransitionButton contract={props.contract} />
      <Button type={'primary'} size={'md'} text={'Bewerken'} onClick={openEditModal}/>
      <Button type={'danger'} size={'md'} text={'Verwijderen'} onClick={openDeleteModal}/>
    </div>
    <div className={"border border-slate-200 dark:border-zinc-600 rounded px-3 py-2 space-y-2"}>
      <h1 className={"text-3xl pb-2"}>Details</h1>
      <div className={"flex space-x-2"}>
        <h1>Status</h1>
        <h1
          className={`text-xs px-1 py-0.5 ${(props.contract.status === 'Concept' || props.contract.status === 'Archief') ? 'dark:bg-zinc-500' : props.contract.status === 'Actief' ? 'dark:bg-green-700' : 'dark:bg-blue-800'} rounded`}>{props.contract.status}</h1>
      </div>
      <div className={"flex space-x-2"}>
        <h1>Klant: </h1>
        <h1 className={`underline hover:dark:text-brand-700 transition duration-300 hover:cursor-pointer ${!customerDetails?.name ? 'text-red-500' : 'text-slate-900 dark:text-zinc-200'}`}
            onClick={() => navigate(`/${tenant.tenant}/customers/${customerDetails?.id}`)}>{customerDetails?.name ?? 'Verwijderde klant'}</h1>
      </div>
      <div className={"flex space-x-2"}>
        <h1>Type contract:</h1>
        <h1>{props.contract.repeatNMonths ? 'Recurring' : 'Fixed'}</h1>
      </div>
      <div className={"flex space-x-2"}>
        <h1>Uren budget:</h1>
        <h1>{formatTimeString(totalTime)} / {formatTimeString(props.contract.hourBudget)}</h1>
      </div>
      <div>
        <h1>{`Looptijd van ${formatDate(props.contract.startAt)} tot ${formatDate(props.contract.endAt)}`}</h1>
      </div>
    </div>
  </div>
}

const ContractTimes: FC <{ times: TicketTime[], tickets: Ticket[] }> = (props) => {
  const tenant= useTenant();
  const navigate = useNavigate();
  const ticketMap = useMemo(() => {
    const map: {[key: string]: Ticket} = {}
    props.tickets.forEach((ticket) => {
      map[ticket.id] = ticket
    })
    return map
  }, [props.tickets])

  return <>
    <h1 className={"text-3xl pb-2"}>Geschreven tijden</h1>
    <DataTable
      keyProperty={'id'} data={props.times.sort((a, b) => a.startedAt.getTime() > b.startedAt.getTime() ? -1: 1)}
      columns={[
        {header: 'User', property: 'user_name'},
        {header: 'Ticket', property: 'ticket_uuid', transform: (id) => {
          const ticket: Ticket|undefined = ticketMap[id]
            const subject = ticket?.subject ?? ''
            return ticket !== undefined ?`#${ticket.number} - ${subject.length > 32 ? subject.slice(0,32) + '...' : subject}`  : '-'
        }},
        {header: 'beschrijving', property: 'description'},
        {header: 'tijd', property: 'value', transform: (_, t) => (
            <div>{formatTimeString(t.value)}</div>
          ),},
        {header: 'Geschreven op', property: 'createdAt', transform: (_, d) => (
            <div>{moment(d.createdAt).format('DD-MM-YYYY')}</div>
          )}
      ]}
      placeholder={<> Nog geen geschreven tijden </>}
      onClickRow={(row) => navigate(`/${tenant.tenant}/ticket/${row.ticket_uuid}`)} />
  </>
}

const ContractTransitionButton: FC<{ contract: Contract }> = (props) => {
  const {editContract} = useApiCall()
  const refresh = useRefresh()

  const transition = async (status: contractStage) => {
    await editContract(props.contract.id, props.contract.name, props.contract.customerId, props.contract.hourBudget, props.contract.timeCategoryId, props.contract.startAt, props.contract.endAt, status, props.contract.repeatNMonths)
    refresh()
  }
  return <div className={'flex space-x-2'}>
    {props.contract.status === 'Concept' && <Button type={'secondary'} size={'md'} text={'Naar Actief'} icon={faForward} onClick={() => transition('Actief')} />}
    {props.contract.status === 'Actief' && <Button type={'secondary'} size={'md'} text={'Naar Concept'} icon={faBackward} onClick={() => transition('Concept')} />}
    {props.contract.status === 'Actief' && props.contract.repeatNMonths === null &&  <Button type={'secondary'} size={'md'} text={'Naar Afgerond'} icon={faForward} onClick={() => transition('Afgerond')} />}
    {props.contract.status === 'Afgerond' && props.contract.repeatNMonths === null && <Button type={'secondary'} size={'md'} text={'Naar Actief'} icon={faBackward} onClick={() => transition('Actief')} />}
    {props.contract.status === 'Afgerond' && <Button type={'secondary'} size={'md'} text={'Naar Archief'} icon={faForward} onClick={() => transition('Archief')} />}
    {props.contract.status === 'Archief' && <Button type={'secondary'} size={'md'} text={'Naar Afgerond'} icon={faBackward} onClick={() => transition('Afgerond')} />}
  </div>
}
