import React, {FC, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDownAZ,
  faChevronDown,
  faChevronUp,
  faFilter,
  faSearch,
  faSliders,
  faTimes,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../../util/usePersistentState";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../../api/dto";
import {Button} from "../form/Button";
import {v4} from "uuid"
import {Select} from "../form/Select";
import {TicketPriorityLabel, TicketStatusLabel} from "./TicketListCard";
import {data} from "autoprefixer";
import {useTenant} from "../../tenant/TenantContext";
import {Autocomplete} from "../form/Autocomplete";
import {useApi} from "../../api/APIContext";
import {usePermission} from "../../permissions/PermissionContext";

const sortOptions = {
  deadlineDesc: 'Deadline',
  createdDesc: 'Nieuwste tickets',
  createdAsc: 'Oudste tickets',
  priorityAsc: 'Hoogste prioriteit',
  updatedAsc: 'Langst geen update',
  updatedDesc: 'Recent geüpdate',
}

type FilterMode = 'and'|'or'
interface Filter {
  id: string
  field: keyof Pick<Ticket, 'status_id'|'priority_id'|'assignee_id'|'team_id'|'user_id'|'customer_id'>
  values: string[]
  invert: boolean
  enable: boolean
}

export const SearchControlBar: FC<{tickets: Ticket[], statuses: TicketStatus[], priorities: TicketPriority[], teams: Team[], customers: Customer[], users: User[], tenantSlug: string, onSearchedTicketsChange: (tickets: Ticket[]) => void}> = (props) => {
  const {currentTenant} = useApi()
  const [search, setSearch] = usePersistentState<string>('ticket-overview-search','')
  const [sort, setSort] = usePersistentState<keyof typeof sortOptions>(`ticket-overview-sort-${props.tenantSlug}`, 'createdDesc')
  const [filterConfiguratorExpanded, setFilterConfiguratorExpanded] = useState<boolean>(false)
  const [filterMode, setFilterMode] = usePersistentState<FilterMode>(`ticket-overview-filter-mode-${props.tenantSlug}`,'and')
  // @ts-ignore Die filter objecten kunnen prima geserialized worden.
  const [filters, setFilters] = usePersistentState<Filter[]>(`ticket-overview-filters-${props.tenantSlug}`, createDefaultFilters(props.statuses))

  const templates = createFilterTemplates(props.statuses, currentTenant?.userId)

  useEffect(() => {
    const activeFilters = filters.filter(f => f.enable)
    const filteredTickets = searchFilterSortTickets(props.tickets, props.customers, props.users, props.priorities, props.teams, search, activeFilters, sort, filterMode)
    props.onSearchedTicketsChange(filteredTickets)
  }, [props.tickets, search, sort, filters, filterMode]);

  return <div className={"mb-8 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded"}>
    <div className={"flex flex-col xl:flex-row items-stretch"}>
      {/* Search */}
      <label
        className={`-ml-[1px] -my-[1px] flex-1 border ${usePermission().canAccessTeam() ? 'xl:rounded-r-none border-transparent rounded' : 'xl:border-r border-slate-200 dark:border-zinc-500 rounded-l'}  flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3`}>
        <FontAwesomeIcon icon={faSearch} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
        <input
          type="text"
          placeholder={'Zoek op ticket nummer, klant, etc.'}
          className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && <button className={"h-8 w-8 flex items-center justify-center text-slate-400 dark:text-zinc-300 hover:text-brand-600 hover:dark:text-brand-500"} onClick={() => setSearch('')}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>}
      </label>
      {/* Filter */}
      {usePermission().canAccessTeam() &&
        <div
          className={`flex-1 flex items-center px-4 py-3 xl:border-r xl:border-l border-slate-200 dark:border-zinc-500`}>
          <FontAwesomeIcon icon={faFilter} className={'mr-3 text-slate-600 dark:text-zinc-300'}/>
          <span className={"flex-1"}>
            <span className={`rounded-full ${filters.filter(x => x.enable).length > 0 ? 'bg-brand-100 dark:bg-brand-300 text-brand-800 dark:text-brand-700' : 'bg-slate-100 text-slate-800'} px-2 mr-1 text-sm font-medium`}>{filters.filter(x => x.enable).length}
            </span> filters actief</span>
          <Button type={'secondary'} size={'sm'} text={'Filters aanpassen'} icon={filterConfiguratorExpanded ? faChevronUp : faChevronDown} onClick={() => setFilterConfiguratorExpanded(x => !x)}/>
        </div>
      }

      {/* Sort*/}
      <div
        className={"-mr-[1px] -my-[1px] flex-1 border rounded xl:rounded-l-none border-transparent flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3"}>
        <FontAwesomeIcon icon={faArrowDownAZ} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
        <select value={sort} onChange={(e) => setSort(e.target.value as keyof typeof sortOptions)}
                className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}>{Object.entries(sortOptions).map(([key, value]) =>
          <option key={key} value={key}>{value}</option>)}</select>
      </div>
    </div>
    {filterConfiguratorExpanded && <div className={"px-4 py-3 border-t border-slate-200 dark:border-zinc-500"}>
      {/* Templates */}
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4"}>
        {templates.map((template, i) => {
          return <button key={i} className={'border border-slate-200 dark:border-zinc-500 hover:bg-slate-50 hover:dark:bg-zinc-600 rounded px-4 py-3'} onClick={() => {
            setFilters(template.filters)
            setFilterMode(template.filterMode)
            setFilterConfiguratorExpanded(false)
          }}>
            <div className={"flex items-center mb-3"}>
              <div className={'bg-slate-600 dark:bg-zinc-500 h-5 w-5 flex items-center justify-center rounded'}>
                <FontAwesomeIcon icon={faSliders} className={"text-white dark:text-zinc-300 text-xs"} />
              </div>
              <span className={"text-slate-600 dark:text-zinc-300 font-medium text-sm ml-2"}>Snelfilter</span>
            </div>
            <h2 className={'text-left font-bold'}>{template.name}</h2>
          </button>
        })}
      </div>
      {/* Customize*/}
      <h3 className={"text-lg font-semibold mt-4 mb-2"}>Filters aanpassen</h3>
      <div className={"h-6 mb-3"}>
        <span className={"text-sm mr-2"}>Modus:</span>
        <button
          className={`text-sm font-medium border ${filterMode === 'and' ? 'border-brand-300 dark:border-brand-700 bg-brand-100 dark:bg-brand-700 text-brand-900 dark:text-brand-200' : 'border-slate-300 dark:border-zinc-500 bg-slate-100 dark:bg-zinc-600 hover:bg-slate-200 hover:dark:bg-zinc-700 text-slate-600 dark:text-zinc-300'} rounded-l px-1`}
          onClick={() => setFilterMode('and')}>AND
        </button>
        <button
          className={`text-sm font-medium border ${filterMode === 'or' ? 'border-brand-300 dark:border-brand-700 bg-brand-100 dark:bg-brand-700 text-brand-900 dark:text-brand-200' : 'border-slate-300 dark:border-zinc-500 bg-slate-100 dark:bg-zinc-600 hover:bg-slate-200 hover:dark:bg-zinc-700 text-slate-600 dark:text-zinc-300'} rounded-r px-1`}
          onClick={() => setFilterMode('or')}>OR
        </button>
      </div>

      <div className={"flex flex-col space-y-2"}>
        {filters.map((filter) => {
          return <ConfigureFilter key={filter.id} filter={filter}
                                  onDelete={() => setFilters(old => old.filter(f => f.id !== filter.id))}
                                  onUpdate={(updatedFilter) => setFilters(old => old.map(f => f.id !== filter.id ? f : updatedFilter))}
                                  statuses={props.statuses} priorities={props.priorities} users={props.users} teams={props.teams} customers={props.customers} />
        })}
        <div className={"flex space-x-4"}>
          <Button type={"secondary"} size={'sm'} text={'Filter toevoegen'} icon={faFilter}
                  onClick={() => setFilters([...filters, {
                    id: v4(),
                    field: 'status_id',
                    values: [],
                    invert: false,
                    enable: true
                  }])}/>
          <Button type={"danger"} size={'sm'} text={'Reset naar standaard'} icon={faTimes}
                  onClick={() => setFilters(createDefaultFilters(props.statuses))}/>
        </div>
      </div>
    </div>}
  </div>
}

function createDefaultFilters(statuses: TicketStatus[]): Filter[] {
  return [
    {id: v4(), field: 'status_id', values: statuses.filter(s => !s.isClosed && !s.isOnHold).map(s => s.id), invert: false, enable: true}
  ]
}

function createFilterTemplates(statuses: TicketStatus[], userId?: string): {filters: Filter[], name: string, filterMode: FilterMode}[] {
  if (!userId) {
    console.warn('Initiating filter template without userId')
  }
  return [
    {
      name: "Open Tickets",
      filterMode: 'and',
      filters: [
        {id: v4(), field: 'status_id', values: statuses.filter(s => !s.isClosed && !s.isOnHold).map(s => s.id), invert: false, enable: true}
      ]
    },
    {
      name: "On-hold Tickets",
      filterMode: 'and',
      filters: [
        {id: v4(), field: 'status_id', values: statuses.filter(s => s.isOnHold).map(s => s.id), invert: false, enable: true}
      ]
    },
    {
      name: "Mijn Tickets",
      filterMode: 'and',
      filters: [
        {id: v4(), field: 'status_id', values: statuses.filter(s => s.isClosed).map(s => s.id), invert: true, enable: true},
        {id: v4(), field: 'assignee_id', values: [userId ?? 'unknown'], invert: false, enable: true},
      ]
    },
    {
      name: "Niet toegewezen",
      filterMode: 'and',
      filters: [
        {id: v4(), field: 'status_id', values: statuses.filter(s => s.isClosed).map(s => s.id), invert: true, enable: true},
        {id: v4(), field: 'assignee_id', values: ['-'], invert: false, enable: true},
      ]
    },
    {
      name: "Ongekoppeld",
      filterMode: 'and',
      filters: [
        {id: v4(), field: 'team_id', values: ['-'], invert: false, enable: true},
        {id: v4(), field: 'user_id', values: ['-'], invert: false, enable: true},
      ]
    },
  ]
}

const ConfigureFilter: FC<{filter: Filter, onDelete: () => void, onUpdate: (filter: Filter) => void, statuses: TicketStatus[], priorities: TicketPriority[], teams: Team[], users: User[], customers: Customer[]}> = (props) => {
  return <div className={"border border-slate-200 dark:border-zinc-500 rounded px-2 py-2 flex"}>
    <label className={"h-10 w-10 mr-2 rounded hover:bg-brand-200 hover:dark:bg-zinc-600 cursor-pointer flex items-center justify-center"}>
      <input type="checkbox" className={"accent-brand-700"} checked={props.filter.enable}
             onChange={(e) => props.onUpdate({...props.filter, enable: e.target.checked})}/>
    </label>
    <div className={"-mt-1 mr-2"}>
      <Select label={''} options={{'status_id': 'Status', 'priority_id': 'Prioriteit', 'assignee_id': 'Toegewezene', 'team_id': 'Team', 'user_id': 'Aanmaker', 'customer_id': 'Klant',}}
              value={props.filter.field}
              onChange={(newField) => props.onUpdate({...props.filter, field: newField as Filter['field'], values: []})}/>
    </div>
    <div className={"-mt-1 mr-2"}>
      <Select label={''} options={{'=': 'is', '!=': 'is niet'}}
              value={props.filter.invert ? '!=' : '='}
              onChange={(newInvert) => props.onUpdate({...props.filter, invert: newInvert === '!='})}/>
    </div>
    <div className={'flex-1'}>
      {props.filter.field === 'status_id' && <div className={"flex flex-wrap -mb-2"}>
        {props.statuses.map((status, i) => {
          return <label key={i} className={`mr-2 mb-2 h-10 pl-2 rounded-lg flex items-center border ${props.filter.values.includes(status.id) ? 'border-slate-600 bg-slate-100 dark:border-zinc-400 dark:bg-zinc-700' : 'border-slate-200 dark:border-zinc-600'}`}>
            <input type="checkbox" className={"mr-2 accent-brand-700"} checked={props.filter.values.includes(status.id)}
                   onChange={(e) => props.onUpdate({...props.filter, values: e.target.checked ? [...props.filter.values, status.id] : props.filter.values.filter(v => v !== status.id)})}/>
            <TicketStatusLabel status={status}/>
          </label>
        })}
      </div>}
      {props.filter.field === 'priority_id' && <div className={"flex flex-wrap -mb-2"}>
        {props.priorities.map((priority, i) => {
          return <label key={i} className={`mr-2 mb-2 h-10 pl-2 rounded-lg flex items-center border ${props.filter.values.includes(priority.id) ? 'border-slate-600 bg-slate-100 dark:border-zinc-400 dark:bg-zinc-700' : 'border-slate-200 dark:border-zinc-600'}`}>
            <input type="checkbox" className={"mr-2 accent-brand-700"} checked={props.filter.values.includes(priority.id)}
                   onChange={(e) => props.onUpdate({...props.filter, values: e.target.checked ? [...props.filter.values, priority.id] : props.filter.values.filter(v => v !== priority.id)})}/>
            <TicketPriorityLabel priority={priority}/>
          </label>
        })}
      </div>}
      {props.filter.field === 'assignee_id' && <div className={"-mt-1 flex"}>
        <div>
          <Select label={''} options={{'-': 'Geen', ...Object.fromEntries(props.users.map(user => [user.id, user.name]))}}
                  value={props.filter.values[0] ?? '-'}
                  onChange={(newAssignee) => props.onUpdate({...props.filter, values: [newAssignee]})}/>
        </div>
      </div>}

      {usePermission().canAccessTeam() && <>
        {props.filter.field === 'team_id' && <div className={"flex flex-wrap -mb-2"}>
          {[{id: '-', team_name: 'Geen'}, ...props.teams].map((team, i) => {
            return <label key={i} className={`mr-2 mb-2 h-10 px-2 rounded-lg flex items-center border ${props.filter.values.includes(team.id) ? 'border-slate-600 bg-slate-100 dark:border-zinc-400 dark:bg-zinc-700' : 'border-slate-200 dark:border-zinc-600'}`}>
              <input type="checkbox" className={"mr-2 accent-brand-700"} checked={props.filter.values.includes(team.id)}
                     onChange={(e) => props.onUpdate({...props.filter, values: e.target.checked ? [...props.filter.values, team.id] : props.filter.values.filter(v => v !== team.id)})}/>
              <span className={"text-sm"}>{team.team_name}</span>
            </label>
          })}
        </div>}
      </>}

      {props.filter.field === 'user_id' && <div className={"-mt-1 flex"}>
        <div>
          <Select label={''} options={{'-': 'Geen', ...Object.fromEntries(props.users.map(user => [user.id, user.name]))}}
                  value={props.filter.values[0] ?? '-'}
                  onChange={(newUser) => props.onUpdate({...props.filter, values: [newUser]})}/>
        </div>
      </div>}
      {props.filter.field === 'customer_id' && <div className={"-mt-1 flex"}>
        <div>
          <Autocomplete label={''} options={{'-': 'Geen', ...Object.fromEntries(props.customers.map(customer => [customer.id, customer.name]))}}
                  value={props.filter.values[0] ?? '-'}
                  onChange={(newCustomer) => props.onUpdate( {...props.filter, values: [newCustomer]})}/>
        </div>
      </div>}
    </div>
    <Button type={'danger'} size={'md'} text={'Verwijderen'} icon={faTimes} onClick={props.onDelete}/>
  </div>
}

function searchFilterSortTickets(tickets: Ticket[], customers: Customer[], users: User[], priorities: TicketPriority[], teams: Team[], search: string, filters: Filter[], sort: keyof typeof sortOptions, filterMode: FilterMode): Ticket[] {
  // 1. Filter
  const filteredTickets = tickets.filter(ticket => {
    const matches = filters.filter(filter => {
      if (filter.field === 'status_id') {
        return filter.values.includes(ticket.status_id) !== filter.invert
      }
      if (filter.field === 'priority_id') {
        return filter.values.includes(ticket.priority_id) !== filter.invert
      }
      if (filter.field === 'assignee_id') {
        return filter.values.includes(ticket.assignee_id ?? '-') !== filter.invert
      }
      if (filter.field === 'team_id') {
        return filter.values.includes(ticket.team_id ?? '-') !== filter.invert
      }
      if (filter.field === 'user_id') {
        return filter.values.includes(ticket.user_id ?? '-') !== filter.invert
      }
      if (filter.field === 'customer_id') {
        return filter.values.includes(ticket.customer_id ?? '-') !== filter.invert
      }
      return false
    })
    // If filtermode is AND, all filters must match, if filtermode is OR, at least one filter must match.
    return filterMode === 'and' ? matches.length === filters.length : matches.length > 0
  })
  // 2. Search
  const customerMap = Object.fromEntries(customers.map(c => [c.id, c]))
  const userMap = Object.fromEntries(users.map(u => [u.id, u]))
  const teamMap = Object.fromEntries(teams.map(t => [t.id, t]))
  const searchedTickets = filteredTickets.filter(ticket => {
    const filterableFields = [
      ticket.subject,
      ticket.number,
      customerMap[ticket.customer_id]?.name,
      userMap[ticket.assignee_id??'-']?.name,
      teamMap[ticket.team_id??'-']?.team_name,
    ]
    if (search.trim() === '') {
      return true
    }
    // There must be at least one match on one field.
    return filterableFields.some(field => field?.toLowerCase().includes(search.trim().toLowerCase()))
  })
  // 3. Sort
  return searchedTickets.sort((a, b) => {
    if (sort === 'priorityAsc') {
      const priorityA = priorities.find((p) => p.id === a.priority_id)
      const priorityB = priorities.find((p) => p.id === b.priority_id)
      if (!priorityA) {
        return -1
      }
      if (!priorityB) {
        return 1
      }
      if (priorityA.order === priorityB.order) {
        // If priorities are equal, sort on created date.
        return a.createdAt < b.createdAt ? 1 : -1
      }
      return priorityA.order > priorityB.order ? 1 : -1
    }
    if (sort === 'deadlineDesc') {
      const date = new Date()

      if (a.closedAt && !b.closedAt) {
        return 1
      }

      if (!a.closedAt && b.closedAt) {
        return -1
      }

      if (a.closedAt && b.closedAt) {
        return a.closedAt < b.closedAt ? 1 : -1
      }

      if (a.sla_deadline && !b.sla_deadline) {
        return -1
      }

      if (!a.sla_deadline && b.sla_deadline) {
        return 1
      }

      if (!a.sla_deadline && !b.sla_deadline) {
        return a.createdAt < b.createdAt ? 1 : -1
      }

      return (a.sla_deadline!.getTime() - date.getTime()) < (b.sla_deadline!.getTime() - date.getTime()) ? -1 : 1
    }
    if (sort === 'createdAsc') {
      return a.createdAt > b.createdAt ? 1 : -1
    }
    if (sort === 'createdDesc') {
      return a.createdAt < b.createdAt ? 1 : -1
    }
    if (sort === 'updatedAsc') {
      return a.updatedAt > b.updatedAt ? 1 : -1
    }
    if (sort === 'updatedDesc') {
      return a.updatedAt < b.updatedAt ? 1 : -1
    }
    return 0;
  })
}