import React, {FC} from "react";
import {Customer, User} from "../../../api/dto";
import {useModal} from "../../layout/ModalProvider";
import {AddCustomerUserModal} from "../../../modals/AddCustomerUserModal";
import {usePermission} from "../../../permissions/PermissionContext";
import {Button} from "../../form/Button";
import {faAdd, faWarning} from "@fortawesome/free-solid-svg-icons";
import {SearchPaginateReload} from "../../data/SearchAndPaginate";
import {useApiCall} from "../../../api/api";
import {useRefresh} from "../../RefreshController";
import {EditCustomerUserModal} from "../../../modals/EditCustomerUserModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DeleteCustomerModal} from "../../../modals/DeleteCustomerModal";
import {DeleteUserModal} from "../../../modals/DeleteUserModal";

export const CustomerUsersTab: FC<{ customer: Customer, users: User[], reload: () => void }> = (props) => {
  const {open: openAddModal} = useModal({title: 'Gebruiker toevoegen', body: <AddCustomerUserModal customer={props.customer}/>, size: "md"
  })
  const filteredUsers = props.users.sort((a, b) => a.name.localeCompare(b.name))
  return <div className={"flex flex-col"}>
    {usePermission().canEditCustomers() &&
      <div className={"flex justify-end p-2"}>
        <Button type={"primary"} size={"sm"} icon={faAdd} text={"Gebruiker toevoegen"} onClick={openAddModal}/>
      </div>
    }
    <SearchPaginateReload data={filteredUsers} placeholder={'Zoek op naam, e-mail of telefoonnummer'}
                          filterProperties={['name', 'email', 'phone',]}
                          onReload={() => props.reload()} paginationEnabled={true} resultsBuilder={(filteredUsers) => {
      return <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'}>
        {filteredUsers.map((user, i) => <CustomerUserCard customer={props.customer} user={user} key={i} />)}
      </div>
    }}/>
  </div>
}

const CustomerUserCard: FC<{ customer: Customer, user: User }> = (props) => {
  const {grantLoginAccess, revokeLoginAccess} = useApiCall()
  const reload = useRefresh()

  const grantAccess = async () => {
    await grantLoginAccess(props.customer.id, props.user.id)
    reload()
  }

  const revokeAccess = async () => {
    await revokeLoginAccess(props.customer.id, props.user.id)
    reload()
  }

  const modal = useModal({title: 'Gebruiker bewerken', body: <EditCustomerUserModal customer={props.customer} user={props.user}/>, size: "md"})
  const delModal = useModal({title: 'Gebruiker verwijderen', body: <DeleteUserModal user={props.user}/>, size: "md"})
  return <div className={"bg-white px-4 py-3 rounded border border-slate-200 dark:bg-zinc-700 dark:border-zinc-600"}>
    <div className={'font-medium text-lg h-6'}>{props.user.first_name} {props.user.middle_name} {props.user.last_name}</div>
    <div className={'text-sm my-2 text-slate-800 dark:text-zinc-400'}>{props.user.email}</div>
    <div className={'text-sm my-2 text-slate-800 dark:text-zinc-400'}>{props.user.phone ?? '-'}</div>
    <div className={"flex flex-col items-stretch space-y-3"}>
      {usePermission().canEditCustomers() && <>
        <Button type={'primary'} size={'sm'} text={'Bewerken'} onClick={() => modal.open()}/>
      </>}
      {usePermission().canAddOrRemoveAccess() && <>
        {(props.user.first_name && props.user.last_name && props.user.email) ? <>
          <Button type={props.user.can_login ? 'danger' : 'secondary'} size={'sm'} text={props.user.can_login ? 'Trek login in' : 'Geef login rechten'} onClick={props.user.can_login ? revokeAccess : grantAccess}/>
        </> : <>
          <div className={'flex items-center space-x-3 border border-yellow-500 rounded px-3 py-2'}>
            <FontAwesomeIcon icon={faWarning} className={'text-yellow-500'}/>
            <div className={'text-yellow-500 text-xs'}>
              Gebruiker heeft geen naam of e-mail adres, login rechten kunnen niet worden toegekend.
            </div>
          </div>
        </>}
      </>
      }
      {usePermission().canEditCustomers() && <>
        <Button type={'danger'} size={'sm'} text={'Verwijderen'} onClick={() => delModal.open()}/>
      </>}
    </div>
  </div>
}