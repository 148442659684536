import {FC, useState} from "react";
import {Customer, CustomerUser, User} from "../api/dto";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export const DeleteUserModal: FC<{ user: User }> = (props) => {
  const { deleteCustomerUser } = useApiCall()
  const reload = useRefresh()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    await deleteCustomerUser(props.user.customer_id, props.user.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }

  return <>
    <p>
      <strong>Let op!</strong> Je staat op het punt om de gebruiker <strong>{props.user.first_name} {props.user.last_name}</strong> te verwijderen uit jouw omgeving.
    </p>
    <p>
      <strong>Weet je het zeker?</strong>
    </p>
    <InputErrors errors={errors} field={'error'}/>
    <ModalFooter icon={faTrash} text={"Ja, verwijderen"} onSubmit={confirm}/>
  </>
}