import React, {FC, useCallback, useState} from "react";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Customer} from "../api/dto";

export const AddCustomerUserModal: FC<{customer: Customer}> = (props) => {
  const {addCustomerUser} = useApiCall()
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback(async () => {
    await addCustomerUser(props.customer.id, firstName, middleName, lastName, email, '', '', '', phone).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload ()
  }, [firstName, middleName, lastName, email, phone])

  return <>
    <form onSubmit={() => save} className={'space-y-4 flex-1'}>
      {/* Name */}
      <div className={"flex space-x-3"}>
        <div className={"basis-2/5"}>
          <Input type={'text'} label={'Voornaam'} value={firstName} onChange={(v) => setFirstName(v)} required={true}
                 placeholder={'John'}/>
          <InputErrors errors={errors} field={'first_name'}/>
        </div>
        <div className={"basis-1/5"}>
          <Input type={'text'} label={'Tussenvoegsel'} value={middleName} onChange={(v) => setMiddleName(v)} required={false}
                 placeholder={'van'}/>
          <InputErrors errors={errors} field={'middle_name'}/>
        </div>
        <div className={"basis-2/5"}>
          <Input type={'text'} label={'Achternaam'} value={lastName} onChange={(v) => setLastName(v)} required={true}
                 placeholder={'Doe'}/>
          <InputErrors errors={errors} field={'last_name'}/>
        </div>
      </div>
      {/* Email */}
      <div>
        <Input type={"text"} label={'E-mail'} value={email} onChange={(v) => setEmail(v)} required={true}
               placeholder={'placeholder@gmail.com'}/>
        <InputErrors errors={errors} field={'email'}/>
      </div>
      {/* Phone */}
      <div>
        <Input type={"text"} label={'Telefoonnummer'} value={phone} onChange={(v) => setPhone(v)} required={false}
               placeholder={'+31 123456789'}/>
        <InputErrors errors={errors} field={'phone'}/>
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}