import React, {FC, useState} from "react";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Contract, Customer, Ticket, TicketTime, TicketTimeCategory} from "../api/dto";
import {faCircleNotch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollar, faDollarCircle, faFile} from "@fortawesome/pro-light-svg-icons";
import {useLocalSettings} from "../components/LocalSettingsContext";
import {useTimeContext} from "../components/time/TimeContext";
import {Input} from "../components/form/Input";
import {FancyAutocomplete} from "../components/form/Autocomplete";
import {Button, IconButton} from "../components/form/Button";
import {faLock} from "@fortawesome/pro-solid-svg-icons";

export const EditTicketTimeModal: FC<{ticket: Ticket, time: TicketTime, contracts: Contract[], categories: TicketTimeCategory[]}> = (props) => {
  const [date, setDate] = useState(props.time.startedAt)
  const [time, setTime] = useState<number>(props.time.value)
  const [category, setCategory] = useState(props.time.category_id ? props.time.category_id : props.time.contract_id ? props.time.contract_id : '')
  const [description, setDescription] = useState(props.time.description)
  const [errors, setErrors] = useState<ErrorBag>({})
  const contract = props.contracts.find(
    (contract) => contract.id === props.time.contract_id
  );

  const options = [
    ...props.contracts
      .filter(contract => contract.customerId === props.ticket.customer_id)
      .filter(contract => contract.status === 'Actief')
      .map(contract => ({
        key: contract.id,
        text: contract.name,
        subText: moment(contract.startAt).format('Do MMM') + ' - ' + moment(contract.endAt).format('Do MMM'),
        leading: <FontAwesomeIcon icon={faFile} className={`text-xs ${contract.timeCategory.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
      }))
      .sort((a, b) => a.text.localeCompare(b.text)), // Sort by contract name
    ...props.categories.map(c => ({
      key: c.id,
      text: c.name,
      subText: c.code,
      leading: <FontAwesomeIcon icon={c.isBillable ? faDollarCircle : faDollar}
                                className={`text-xs ${c.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
    }))
      .sort((a, b) => a.subText.localeCompare(b.subText)) // Sort by category code
  ];
  const [loading, setLoading] = useState(false)
  const {enrolledInTimeBeta} = useLocalSettings()
  const reload = useRefresh()
  const {editTicketTimeWithCategory, editTicketTimeWithContract} = useApiCall()
  const {close} = useModalControls()

  const save = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    if (props.categories.some((m) => m.id === category)) {
      await editTicketTimeWithCategory(props.ticket.id, props.time.id, date, time, description, category).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
        throw error
      }).finally(() => setLoading(false))
      reload()
      close()
      setLoading(false)
    } else {
      await editTicketTimeWithContract(props.ticket.id, props.time.id, date, time, description, category).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
        throw error
      }).finally(() => setLoading(false))
      reload()
      close()
      setLoading(false)
    }
  }

  if (enrolledInTimeBeta) {
    return <form className={"grid grid-cols-3 gap-x-4 gap-y-3 w-full pb-5"} onSubmit={save}>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'date'} label={'Datum'} value={date} onChange={setDate}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'time'} label={'Start tijd'} value={date.getHours() * 60 + date.getMinutes()}
               onChange={(minutes) => setDate(oldDate => {
                 const newDate = new Date(oldDate)
                 newDate.setHours(Math.floor(minutes / 60), minutes % 60)
                 return newDate
               })}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'time'} label={'Eind tijd'} value={date.getHours() * 60 + date.getMinutes() + time}
               onChange={(minutes) => {
                 const start = date.getHours() * 60 + date.getMinutes()
                 let diff = minutes - start
                 if (diff < 0) {
                   diff += 60 * 24
                 }
                 setTime(diff)
               }}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'value'}/>
        <Input type={'time'} label={'Duur'} value={time} onChange={setTime}/>
      </div>
      <div className={"col-span-2"}>
        <InputErrors errors={errors} field={'category'}/>
        {contract && contract.status !== "Concept" && contract.status !== "Archief" ?
          <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                             options={options}/> : !contract ?
            <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                               options={options}/>
            :
            <div className={"flex-col text-zinc-400"}>
              <div>Post</div>
              <div className={"flexitems-center py-1.5 px-2 border dark:border-zinc-600 dark:bg-zinc-600 rounded"}>
                <FontAwesomeIcon icon={faLock}/><span className={"ml-2"}>{contract && contract.name}</span></div>
            </div>
        }
      </div>
      <div className={"col-span-2"}>
        <InputErrors errors={errors} field={'description'}/>
        <Input type={'text'} label={'Omschrijving'} value={description} onChange={setDescription}/>
      </div>
      <div className={'flex'}>
        <div className={"flex-1 flex flex-col items-stretch justify-end"}>
          {loading ? <>
            <div className={'text-slate-600 dark:text-zinc-400 flex items-center justify-center h-10'}>
              <FontAwesomeIcon spin={true} icon={faCircleNotch}/>
            </div>
          </> : <>
            <Button type={'primary'} submit size={'md'} text={"Bewerken"} disabled={(!contract && !category) || ['Concept', 'Archief'].includes(contract?.status??'')} />
          </>}
        </div>
      </div>
    </form>
  }
  return <form className={"grid grid-cols-2 gap-x-4 gap-y-3 w-full pb-5"} onSubmit={save}>
    <div className={""}>
      <InputErrors errors={errors} field={'created_at'}/>
      <Input type={'date'} label={'Datum'} value={date} onChange={setDate}/>
    </div>
    <div className={""}>
      <InputErrors errors={errors} field={'value'}/>
      <Input type={'time'} label={'Tijd'} value={time} onChange={setTime}/>
    </div>
    <div className={"col-span-2"}>
      <InputErrors errors={errors} field={'category'}/>
      {contract && contract.status !== "Concept" && contract.status !== "Archief" ?
        <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                           options={options}/> : !contract ?
          <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                             options={options}/>
       :
        <div className={"flex-col text-zinc-400"}>
          <div>Post</div>
          <div className={"flexitems-center py-1.5 px-2 border dark:border-zinc-600 dark:bg-zinc-600 rounded"}>
            <FontAwesomeIcon icon={faLock}/><span className={"ml-2"}>{contract && contract.name}</span></div>
        </div>
      }
    </div>
    <div className={"flex-1 col-span-2"}>
      <InputErrors errors={errors} field={'description'}/>
      <Input type={'text'} label={'Omschrijving'} value={description} onChange={setDescription}/>
    </div>
    <div className={"flex flex-col items-stretch"}>
      {loading ? <>
        <div className={'text-slate-600 dark:text-zinc-400 flex items-center justify-center h-10'}>
          <FontAwesomeIcon spin={true} icon={faCircleNotch}/>
        </div>
      </> : <>
        <Button type={'primary'} submit size={'md'} text={"Bewerken"} disabled={(!contract && !category) || ['Concept', 'Archief'].includes(contract?.status??'')} />
      </>}
    </div>
  </form>
}