import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Select} from "../components/form/Select";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Customer, TicketPriority} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {InputErrors} from "../components/form/InputErrors";
import {usePersistentState} from "../util/usePersistentState";

export const AddTicketTemplateModal: FC<{priorities: TicketPriority[], customers: Customer[]}> = (props) => {
  const {createTicketTemplate} = useApiCall()
  const refactoredPriorities = props.priorities.map((item) => [item.id, item.priority])
  const refactoredCustomers = [
    ['-', 'Geen'],
    ...props.customers.map((item) => [item.id, item.name])
  ]
  const [name, setName] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [priority, setPriority] = useState(props.priorities[0].id)
  const [customer, setCustomer] = useState('-')
  const [type, setType] = useState<'incident'|'service_request'|'change'|'problem'>('change') // not being used for now
  // const [team, setTeam] = useState('') // not being used for now
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback( async () => {
    const promise = createTicketTemplate(
      name,
      type,
      title,
      null,
      description,
      priority,
      customer === '-' ? null : customer,
    ).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    setDescription('')
    setTitle('')
    await promise
    reload()
  }, [title, description, priority, customer])

  return <>
    <form onSubmit={() => save()} className={"space-y-4 flex-1"}>
      <div className={"items-center"}>
        <Input label={"Naam"} type={"text"} value={name} onChange={(v) => setName(v)}/>
        <InputErrors errors={errors} field={'name'}/>
      </div>
      <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3 w-full'}>
        <button type={'button'} onClick={() => setType('change')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'change' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Change</h2>
          <p className={'mt-1 text-xs'}>Wijziging in infrastructuur of diensten</p>
        </button>
        <button type={'button'} onClick={() => setType('service_request')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'service_request' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Service Request</h2>
          <p className={'mt-1 text-xs'}>Verzoek voor standaarddienst of toegestane informatie</p>
        </button>
      </div>
      <div className={"items-center"}>
        <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
        <InputErrors errors={errors} field={'subject'}/>
      </div>
      <div className={"flex space-x-2"}>
        <Select label={"Prioriteit"} options={Object.fromEntries(refactoredPriorities)} value={priority}
                onChange={(v) => setPriority(v)}/>
        <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                      onChange={(v) => setCustomer(v)}/>
      </div>
      <div className={"items-center"}>
        <Input label={"Beschrijving"} type={"textarea"} value={description} onChange={(v) => setDescription(v)}/>
        <InputErrors errors={errors} field={'description'}/>
      </div>
      <InputErrors errors={errors} field={'customer_uuid'}/>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}