import React, {FC, useMemo, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd,
  faBuilding, faCheck,
  faChevronRight, faCopy,
  faEnvelope, faFilter, faPencil,
  faPerson,
  faPhone,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../api/api";
import {useModal} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useTenant} from "../tenant/TenantContext";
import {useRefreshEffect} from "../components/RefreshController";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button} from "../components/form/Button";
import {Customer, Tag, User} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {AddRelationModal} from "../modals/AddRelationModal";
import {Skeleton} from "../components/data/Skeleton";
import {TagPill} from "../components/content/TagPill";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimplePaginate} from "../components/data/SimplePaginate";
import {usePersistentState} from "../util/usePersistentState";
import {EditContactModal} from "../modals/EditContactModal";
import {EditRelationModal} from "../modals/EditRelationModal";

type RelationOrContactWithTags = {
  id: string
  customerId: string
  type: 'relation',
  customer: Customer,
  text: string,
  subText: string|null,
  contactPerson: string|null,
  contactEmail: string|null,
  contactSecondEmail: null,
  contactPhone: string|null,
  tags: Tag[],
} | {
  id: string
  customerId: string
  type: 'contact',
  user: User,
  customer: Customer,
  text: string,
  subText: string|null,
  contactPerson: null,
  contactEmail: string|null,
  contactSecondEmail: string|null,
  contactPhone: string|null,
  tags: Tag[],
}

export const Relations: FC = () => {
  const {getCustomers} = useApiCall()
  const {open: openCreateModal} = useModal({title: 'Relatie toevoegen', body: <AddRelationModal />, size: "md"})
  const {resource: customers, reload: reloadCustomers, loading: loadingCustomers} = useFetchedResource(() => getCustomers())
  const {tenant} = useTenant()
  useRefreshEffect(() => {
    reloadCustomers(undefined)
  })

  const customersAndContacts = useMemo(() => {
    const list: RelationOrContactWithTags[] = [];
    customers?.filter((c: Customer) => c.deletedAt === null).forEach(customer => {
      list.push({
        id: customer.id,
        customerId: customer.id,
        type: 'relation',
        customer: customer,
        text: customer.name,
        subText: customer.address,
        contactPerson: customer.contact_person,
        contactEmail: customer.email,
        contactSecondEmail: null,
        contactPhone: customer.phone,
        tags: customer.tags.map(tagCustomer => tagCustomer.tag).filter(t => t !== null) as Tag[],
      })
      customer.users.forEach(contact => {
        list.push({
          id: contact.id,
          customerId: customer.id,
          type: 'contact',
          user: contact,
          customer: customer,
          text: contact.name,
          subText: ((contact.position??'') + ' bij ' + (customer.name ?? '(niet bekend)')).trim(),
          contactPerson: null,
          contactEmail: contact.email,
          contactPhone: contact.phone,
          contactSecondEmail: contact.secondary_email,
          tags: contact.tag_users.map(tagUser => tagUser.tag).filter(t => t !== null) as Tag[],
        })
      })
    })
    return list.sort((a, b) => {
      if (a.text === null || a.text === "") return 1;
      if (b.text === null || b.text === "") return -1;
      return a.text.localeCompare(b.text);
    });
  }, [customers])

  return (
    <ContentContainer size={'xl'}>
      <PageIconHeader icon={faBuilding}>
        <Breadcrumbs crumbs={[
          {label: "CRM", href: `/${tenant}/relations`},
        ]} currentPage={"Relaties"} />
        <PageHeader>Relaties</PageHeader>
      </PageIconHeader>

      <Callout>
        <SectionHeader>Beheer je relaties</SectionHeader>
        <Paragraph>
          Relaties zijn te organiseren door middel van labels.
        </Paragraph>
        <Button size={"sm"} type={"primary"} text={"Relatie toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
      </Callout>

      {loadingCustomers ? <Skeleton type={'card'} /> : <>
        <CustomersAndContactsList customersAndContacts={customersAndContacts} />
      </>
      }
    </ContentContainer>
  )
}

const CustomersAndContactsList: FC<{customersAndContacts: RelationOrContactWithTags[]}> = ({customersAndContacts}) => {
  const [relationCheckbox, setRelationCheckbox] = usePersistentState<boolean>('relationCheckbox',true)
  const [contactCheckbox, setContactCheckbox] = usePersistentState<boolean>('contactCheckbox', true)
  const preparedData = useMemo(() => {
    return customersAndContacts.map(cac => {
      return {
        ...cac,
        tagString: cac.tags.map(tag => tag.name).join(', '),
      }
    })
  }, [customersAndContacts])
  const filteredPreparedData = preparedData.filter(item =>
    (relationCheckbox && item.type === 'relation') ||
    (contactCheckbox && item.type === 'contact')
  );

  const [justCopied, setJustCopied] = useState(false)
  function copy(text: string) {
    navigator.clipboard.writeText(text)
    setJustCopied(true)
    setTimeout(() => setJustCopied(false), 1000)
  }
  return <div>
    <SearchPaginateReload data={filteredPreparedData}
                          filterProperties={[
                            'text',
                            'subText',
                            'tagString',
                            'contactPerson',
                            'contactEmail',
                            'contactPhone',
                            'contactEmail',
                            'contactSecondEmail',
                            'contactPhone',
                          ]}
                          paginationEnabled={false}
                          placeholder={'Zoek op naam, e-mail, telefoonnummer, etc.'}
                          resultsBuilder={(filteredResults) => {
      const emails = Array.from(new Set(filteredResults.map(fr => fr.contactEmail?.trim()).filter(e => !!e)))
      return <div
        className={'border border-slate-100 dark:border-zinc-600 rounded bg-white dark:bg-zinc-700 overflow-hidden mb-4'}>
        <div className={'border border-slate-100 dark:border-zinc-600 rounded bg-white dark:bg-zinc-700 overflow-hidden px-4 py-3 mx-4 my-4 h-12 text-sm flex items-center'}>
          <div className={''}>
            Huidige selectie: <strong>{filteredResults.length}</strong> resultaten.
          </div>
          <div className={"flex flex-1 ml-4 space-x-2"}>
            <label className={"flex space-x-2 rounded border border-slate-100 dark:border-zinc-600 px-2 py-1 bg-white dark:bg-zinc-700 hover:bg-slate-100 dark:hover:bg-zinc-600 hover:cursor-pointer"}>
              <input type={"checkbox"} className={"accent-brand-300 dark:accent-brand-700"} checked={relationCheckbox} onChange={() => setRelationCheckbox(!relationCheckbox)}/>
              <h1>Relaties</h1>
            </label>
            <label className={"flex space-x-2 rounded border border-slate-100 dark:border-zinc-600 px-2 py-1 bg-white dark:bg-zinc-700 hover:bg-slate-100 dark:hover:bg-zinc-600 hover:cursor-pointer"}>
              <input type={"checkbox"} className={"accent-brand-300 dark:accent-brand-700"} checked={contactCheckbox} onChange={() => setContactCheckbox(!contactCheckbox)}/>
              <h1>Contacten</h1>
            </label>
          </div>
          <div>
            {justCopied ? <span className={'text-brand-600 dark:text-brand-200'}><FontAwesomeIcon icon={faCheck} className={'mr-1'} /> Gekopiëerd!</span> : <Button type={'secondary'} size={'sm'} text={`Kopiëer ${emails.length} ${emails.length === 1 ? 'e-mail adres' : 'e-mail adressen'}`} icon={faCopy} onClick={() => {
              copy(emails.join('; '))
            }}/>}
          </div>
        </div>
        <SimplePaginate data={filteredResults} paginationStyle={"borderless"} resultsBuilder={(results) => {
          return <div
            className={''}>
            {results.map((item, i) => <CustomerOrContactCard customerOrContact={item} key={i}/>)}
          </div>
        }}/>
      </div>
                          }}/>
    {filteredPreparedData.length === 0 && <div className={'flex flex-col rounded border border-slate-200 dark:border-zinc-600 items-center justify-center pt-6 pb-6'}>
      <FontAwesomeIcon icon={faFilter} className={"mb-8 text-4xl"} />
      <h2>Er zijn {preparedData.length - filteredPreparedData.length} relaties en/of contacten die niet door je zoekopdracht of filter heen komen</h2>
      <p className={"font-normal text-regular mt-4"}>Verbreed het filter/zoekopdracht om meer relaties en/of contacten te zien</p>
    </div>}
  </div>
}
const CustomerOrContactCard: FC<{ customerOrContact: RelationOrContactWithTags}> = (props) => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const editContactModal = useModal({title: 'Contact bewerken', body: props.customerOrContact.type === "contact" ? <EditContactModal customer={props.customerOrContact.customer} contact={props.customerOrContact.user} /> : <></>, size: 'lg'})
  const editRelationModal = useModal({title: 'Relatie bewerken', body: <EditRelationModal customer={props.customerOrContact.customer} />, size: 'lg'})

  return <div
    className={`flex items-stretch px-4 py-3 border-t border-slate-100 dark:border-zinc-600 hover:bg-slate-50 dark:hover:bg-zinc-600 cursor-pointer`}
    onClick={() => navigate(`/${tenant}/relations/${props.customerOrContact.customerId}`)}>
    {/* Avatar */}
    <div
      className={"flex items-center justify-center h-12 w-12 font-medium text-brand-600 dark:text-brand-200 bg-brand-100 dark:bg-brand-700 border border-brand-100 dark:border-brand-800 rounded"}>
      <FontAwesomeIcon icon={props.customerOrContact.type === 'relation' ? faBuilding : faPerson}/>
    </div>
    {/* Main */}
    <div className={"flex-1 mx-4"}>
      <div className={"h-10 flex mb-4"}>
        <div className={"flex-1 flex flex-col justify-between"}>
          <div className={"font-medium"}>
            {props.customerOrContact.text}
          </div>
          <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
            {props.customerOrContact.subText}
          </div>
        </div>
        <div className={"flex-1 flex flex-col justify-between"}>
          {props.customerOrContact.type === 'relation' ? <>
            {props.customerOrContact.contactPerson && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faUser}/>{props.customerOrContact.contactPerson}
            </div>}
            {props.customerOrContact.contactEmail && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faEnvelope}/>{props.customerOrContact.contactEmail}
            </div>}
            {props.customerOrContact.contactPhone && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faPhone}/>{props.customerOrContact.contactPhone}
            </div>}
          </> : <>
            {props.customerOrContact.contactEmail && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faEnvelope}/>{props.customerOrContact.contactEmail}
            </div>}
            {props.customerOrContact.contactSecondEmail && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faEnvelope}/>{props.customerOrContact.contactSecondEmail}
            </div>}
            {props.customerOrContact.contactPhone && <div className={"text-sm text-slate-400 dark:text-zinc-300"}>
              <FontAwesomeIcon className={"w-3 mr-2"} icon={faPhone}/>{props.customerOrContact.contactPhone}
            </div>}
          </>}
        </div>
      </div>
      <div className={"flex flex-wrap gap-1 mt-1"}>
        {props.customerOrContact.tags.map((tag, i) => <TagPill key={i} tag={tag} size={'xs'}/>)}
      </div>
    </div>
    <div className={'flex items-center justify-center space-x-2'}>
      {props.customerOrContact.type === 'contact' && (<button className={"z-10 text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-500 cursor-pointer text-slate-600 dark:text-slate-300"} onClick={(e) => {e.stopPropagation(); editContactModal.open();}}><FontAwesomeIcon icon={faPencil} /></button>)}
      {props.customerOrContact.type === 'relation' && (<button className={"text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-500 cursor-pointer text-slate-600 dark:text-slate-300"} onClick={(e) => {e.stopPropagation(); editRelationModal.open();}}><FontAwesomeIcon icon={faPencil} /></button>)}
      <button className={"text-sm h-8 w-8 border border-slate-200 dark:border-zinc-500 rounded-full hover:bg-slate-100 dark:hover:bg-zinc-500 cursor-pointer text-slate-600 dark:text-slate-300"}><FontAwesomeIcon icon={faChevronRight} className={"text-slate-400 dark:text-zinc-300"}/></button>
    </div>
  </div>
}