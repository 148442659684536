import React, {FC, useEffect} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {Customer, DashboardReport, Deal, Team, Ticket, TicketPriority, TicketStatus, User} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {useApiCall} from "../api/api";
import {useApi, useFetchedResource} from "../api/APIContext"
import {Piechart} from "../components/data/Piechart";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {DataTable} from "../components/data/DataTable";
import {TagPill} from "../components/content/TagPill";
import {DealDashboard} from "./Deals";
import {Skeleton} from "../components/data/Skeleton";
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakTokenParsed} from "keycloak-js";
import moment from "moment";
import {formatTimeString} from "../util/formatTimeString";
import {usePermission} from "../permissions/PermissionContext";
import {WeeklyHoursGraph} from "../components/dashboard/WeeklyHoursGraph";


export const Dashboard: FC = () => {
  const {tenant} = useTenant()
  const {keycloak} = useKeycloak()
  const {getTenant} = useApiCall()
  const {resource: currentTenant, reload: reloadTenant} = useFetchedResource(() => getTenant())
  const canAccessTicketTimes = usePermission().canAccessTicketTimes()
  useEffect(() => {
    reloadTenant(undefined)
  }, [tenant]);

  return (
    <ContentContainer size={"xl"}>
      <PageIconHeader icon={faChartLine}>
        <Breadcrumbs crumbs={[
        ]} currentPage={"Dashboard"} />
        <PageHeader>Dashboard</PageHeader>
      </PageIconHeader>

      {keycloak.tokenParsed && <Welcome me={keycloak.tokenParsed}/>}

      {currentTenant?.modules.includes('service_desk') ?
          canAccessTicketTimes ? <ServiceDeskDashboard /> : <ServiceDeskCustomerDashboard />
          : ''
            // <>
            //   <div className={'bg-slate-100 dark:bg-zinc-700 animate-pulse h-72 rounded mb-8'}></div>
            //   <div className={'bg-slate-100 dark:bg-zinc-700 animate-pulse h-96 rounded mb-8'}></div>
            // </>
      }

      {currentTenant?.modules.includes('crm') ?
        <CrmDashboard/> : <></>}

    </ContentContainer>
  )
}

const Welcome: FC<{me: KeycloakTokenParsed}> = (props) => {
  return <>
    <h1 className={"text-4xl font-bold mb-14"}>Welkom, {props.me.given_name} {props.me.family_name} </h1>
  </>
}

const ServiceDeskDashboard: FC = () => {
  const {tenant} = useTenant()
  const {getAllTicketsForTenant, getAllUsersForTenant, getCustomers, getAllPriorities, getAllStatuses, getDashboardReport, getTeams} = useApiCall()
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketStatuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {resource: dashboardReport, loading: loadAverageTime, reload: reloadAverageTime} = useFetchedResource(() => getDashboardReport())
  useEffect(() => {
    reloadTickets(undefined)
    reloadPriorities(undefined)
    reloadStatuses(undefined)
    reloadAverageTime(undefined)
    reloadCustomers(undefined)
    reloadUsers(undefined)
    reloadTeams(undefined)
  }, [tenant]);

  return <div className={"mb-12"}>
    <h1 className={"text-2xl mt-2 mb-3"}>Service Desk</h1>
    {(tickets && ticketStatuses && ticketPriorities && dashboardReport) ? <>
      <Graphs tickets={tickets} statuses={ticketStatuses} priorities={ticketPriorities}
              dashboardReport={dashboardReport}/>
    </> : <>
      <div className={'bg-slate-100 dark:bg-zinc-700 animate-pulse h-72 rounded mb-8'}></div>
    </>}
  {(ticketStatuses &&ticketPriorities && customers && users && teams) ? <>
    <TicketList tickets={tickets ?? []} reload={() => reloadTickets} statuses={ticketStatuses} priorities={ticketPriorities} customers={customers} users={users} teams={teams ?? []} />
  </> : <>
    <div className={'bg-slate-100 dark:bg-zinc-700 animate-pulse h-[34rem] rounded mb-8'}></div>
  </>}
  </div>
}

const ServiceDeskCustomerDashboard: FC<{}> = (props) => {
  const {tenant} = useTenant()
  const {getAllTicketsForTenant, getAllUsersForTenant, getCustomers, getAllPriorities, getAllStatuses} = useApiCall()
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketStatuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  useEffect(() => {
    reloadTickets(undefined)
    reloadPriorities(undefined)
    reloadStatuses(undefined)
    reloadCustomers(undefined)
    reloadUsers(undefined)
  }, [tenant]);
  const priorityCounts = tickets && ticketPriorities ? getPriorityCounts(tickets, ticketPriorities) : [];

  return <div className={"mb-12"}>
    <h1 className={"text-2xl mt-2 mb-3"}>Service Desk</h1>
    {/*total tickets*/}
    <div className={"my-1 mb-10 flex space-x-3"}>
      <div className={'h-36 w-80 flex flex-col bg-zinc-700 border border-zinc-500 rounded justify-center items-center'}>
        <h1 className={"text-3xl pb-2"}>{tickets?.length}</h1>
        <h1 className={'text-sm text-slate-300'}>Tickets</h1>
      </div>
      {/*ticket breakdown*/}
        <div className={'h-36 flex flex-1 bg-zinc-700 border border-zinc-500 rounded justify-evenly'}>
          {priorityCounts.map((priority) => {
            return <div key={priority.name} className={'flex flex-col justify-center items-center'}>
              <h1 className={"text-3xl pb-2"}>{priority.count}</h1>
              <h1 className={'text-sm text-slate-300'}>{priority.name}</h1>
            </div>
          })}
        </div>
    </div>

    {/*List of the 5 most recent tickets*/}
    {(ticketStatuses && ticketPriorities && customers && users) ? <>
      <TicketList tickets={tickets ?? []} reload={() => reloadTickets} statuses={ticketStatuses}
                  priorities={ticketPriorities} customers={customers} users={users} teams={[]}/>
    </> : <>
      <div className={'bg-slate-100 dark:bg-zinc-700 animate-pulse h-[34rem] rounded mb-8'}></div>
    </>}
  </div>
}

const CrmDashboard: FC = () =>  {
  const {tenant} = useTenant()
  const {currentTenant} = useApi()
  const {getCustomers, getDeals} = useApiCall()
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: deals, reload: reloadDeals} = useFetchedResource(() => getDeals())
  useEffect(() => {
    reloadCustomers(undefined)
    reloadDeals(undefined)
  }, [tenant]);

  return <>
    <NewestCustomers customers={customers ?? []} reload={() => reloadCustomers} tenant={tenant ?? ''}/>
    <DashDeals tenant={tenant ?? ''} deals={deals ?? []} userId={currentTenant?.userId} />
  </>
}

const NewestCustomers: FC<{customers: Customer[], reload: () => void, tenant: string}> = (props) => {
  const navigate = useNavigate()
  const filteredCustomers = props.customers.sort((a, b) => a.updatedAt.getTime() > b.updatedAt.getTime() ? -1 : 1).slice(0, 5);
  return <div className={"mb-12"}>
    <h1 className={"text-2xl mt-2 mb-3"}>CRM</h1>
    <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Recente klanten</p>
    <DataTable<Customer>
      keyProperty={'id'}
      data={filteredCustomers}
      columns={[
        {
          header: 'Naam',
          property: 'name'
        },
        {
          header: 'Tags',
          property: 'name',
          transform: (_, customer) => {
            if (customer.tags.length === 0) {
              return <div>-</div>
            }
            return <div className={"flex flex-wrap gap-1"}>
              {customer.tags.map(tagCustomer => <TagPill tag={tagCustomer.tag!} size={'sm'} />)}
            </div>;
          }
        },
        {
          header: 'E-mail',
          property: 'email'
        },
        {
          header: 'Telefoon',
          property: 'phone'
        },
        {
          header: 'Contactpersoon',
          property: 'contact_person'
        },
      ]}
      placeholder={<>Nog geen relaties</>}
      onClickRow={(customer) => navigate(`/${props.tenant}/relations/${customer.id}`)}
    />
  </div>
}

const DashDeals: FC<{deals: Deal[], tenant: string, userId?: string}> = (props) => {
  const filteredDeals = props.deals.filter(d => d.user?.id === props.userId)
  return <>
    <h1 className={"text-2xl "}>Jouw deals</h1>
    {filteredDeals ? <DealDashboard deals={filteredDeals} tenant={props.tenant} showClosed={false}/> : <Skeleton type={"card"} />}
  </>
}

const Graphs: FC<{ tickets: Ticket[], statuses: TicketStatus[], priorities: TicketPriority[], dashboardReport: DashboardReport}> = (props) => {
  const {currentTenant} = useApi()
  const openTickets = props.tickets.filter(ticket => !ticket.status?.isClosed)
  const statusCounts = getStatusCounts(openTickets, props.statuses)
  const priorityCounts = getPriorityCounts(openTickets, props.priorities)
  const ticketsClosedThisWeek = props.tickets
    .filter(ticket => ticket.assignee_id === currentTenant?.userId)
    .filter(ticket => ticket.closedAt !== null && moment(ticket.closedAt).isAfter(moment().startOf('week')))
    .length
  const assignedTickets = openTickets
    .filter(ticket => ticket.assignee_id === currentTenant?.userId)
    .length
  return <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-8"}>
    <div className={'col-span-1 lg:col-span-2 xl:col-span-3'}>
      <WeeklyHoursGraph report={props.dashboardReport} />
    </div>
    <div
      className={"border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 rounded grid grid-cols-1 lg:grid-cols-2 p-2 dark:text-zinc-300"}>
      <div className={"flex flex-col justify-center items-center"}>
        <span className={"text-4xl font-bold mb-2 text-black dark:text-white"}>
          {assignedTickets}
        </span>
        <h2 className={"font-medium text-sm text-center"}>
          Open tickets<br/>
          <span className={'opacity-75 text-xs'}>toegewezen aan jou</span>
        </h2>
      </div>
      <div className={"flex flex-col justify-center items-center"}>
        <span className={"text-4xl font-bold mb-2 text-black dark:text-white"}>
          {ticketsClosedThisWeek}
        </span>
        <h2 className={"font-medium text-sm text-center"}>
          Tickets gesloten<br/>
          <span className={'opacity-75 text-xs'}>in de deze week</span>
        </h2>
      </div>
      <div className={"flex flex-col justify-center items-center"}>
        <span className={"text-4xl font-bold mb-2 text-black dark:text-white"}>
          {formatTimeString(props.dashboardReport.hoursWrittenThisWeek)}
        </span>
        <h2 className={"font-medium text-sm text-center"}>
          Uren geschreven<br/>
          <span className={'opacity-75 text-xs'}>in de deze week</span>
        </h2>
      </div>
      <div className={"flex flex-col justify-center items-center"}>
        <span className={"text-4xl font-bold mb-2 text-black dark:text-white"}>
          {formatTimeString(props.dashboardReport.billableHoursWrittenThisWeek)}
        </span>
        <h2 className={"font-medium text-sm text-center"}>
          Billable geschreven<br/>
          <span className={'opacity-75 text-xs'}>in de deze week</span>
        </h2>
      </div>
    </div>
    <div
      className={"min-h-72 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 rounded flex flex-col justify-center items-center"}>
      <Piechart data={statusCounts} cx={110} cy={150} innerRadius={60} outerRadius={90} labelText={"Tickets"}
                labelValue={String(openTickets.length)}/>
    </div>
    <div
      className={"min-h-72 border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 rounded flex flex-col justify-center items-center"}>
      <Piechart data={priorityCounts} cx={110} cy={150} innerRadius={60} outerRadius={90} labelText={"Tickets"}
                labelValue={String(openTickets.length)}/>
    </div>
  </div>
}


const TicketList: FC<{
  tickets: Ticket[],
  reload: () => void,
  statuses: TicketStatus[],
  customers: Customer[],
  users: User[],
  priorities: TicketPriority[],
  teams: Team[]
}> = props => {
  const navigate = useNavigate()
  const {currentTenant} = useApi()
  const {tenant} = useTenant()
  const filteredTickets = props.tickets
  {usePermission().canAccessTicketTimes() ?
  filteredTickets
    .filter(t => t.assignee_id === currentTenant?.userId)
    .sort((a, b) => a.updatedAt.getTime() > b.updatedAt.getTime() ? -1 : 1)
    .slice(0, 5)
    :
    filteredTickets
      .sort((a, b) => a.updatedAt.getTime() > b.updatedAt.getTime() ? -1 : 1)
      .slice(0, 5);
  }

  return <div>
    <p className={"text-md font-semibold text-slate-700 dark:text-zinc-300 mx-1"}>Recente tickets</p>
    {filteredTickets.map((ticket, i) => <TicketListCard
      key={i}
      ticket={ticket}
      isFirst={i===0}
      isLast={i===(filteredTickets.length - 1)}
      team={props.teams.find(team => team.id === ticket.team_id) ?? null}
      customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
      status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
      priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
      assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
    />)}
  </div>
}

const getStatusCounts = (tickets: Ticket[], statuses: TicketStatus[]): { name: string; count: number }[] => {
  const countMap: { [statusId: string]: number } = {};
  tickets.forEach(ticket => {
    countMap[ticket.status_id] = (countMap[ticket.status_id] || 0) + 1;
  });

  return statuses.map(status => ({
    name: status.status,
    count: countMap[status.id] || 0,
  }));
};


const getPriorityCounts = (tickets: Ticket[], priorities: TicketPriority[]): { name: string; count: number }[] => {
  const countMap: { [priorityId: string]: number } = {};
  tickets.forEach(ticket => {
    countMap[ticket.priority_id] = (countMap[ticket.priority_id] || 0) + 1;
  });

  const countsArray = Object.entries(countMap).map(([priorityId, count]) => ({
    name: priorityId,
    count: count,
  }));

  countsArray.forEach((item, index) => {
    const priority = priorities.find(priority => priority.id === item.name);
    if (priority) {
      countsArray[index].name = priority.priority;
    }
  });

  return countsArray;
};
