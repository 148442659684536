import {FC, useState} from "react";
import {Customer} from "../api/dto";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import {useNavigate} from "react-router-dom";
import {useTenant} from "../tenant/TenantContext";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export const DeleteCustomerModal: FC<{ customer: Customer }> = (props) => {
  const { deleteCustomer } = useApiCall()
  const reload = useRefresh()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<ErrorBag>({})
  const { tenant } = useTenant()

  const confirm = async () => {
    const deployment = await deleteCustomer(props.customer.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`${tenant}/customers`)
  }

  return <>
    <p>
      <strong>Let op!</strong> Je staat op het punt om de klant <strong>{props.customer.name}</strong>
      te verwijderen uit jouw omgeving.
    </p>
    <p>
      <strong>Weet je het zeker?</strong>
    </p>
    <InputErrors errors={errors} field={'error'}/>
    <ModalFooter icon={faTrash} text={"Ja, verwijderen"} onSubmit={confirm}/>
  </>
}